import React from "react";
import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface UsageBarProps {
  percentageUsed: number;
  t: any;
}

export default function UsageBar({ percentageUsed, t }: UsageBarProps) {
  const remainingPercentage = 100 - (percentageUsed || 0) * 100;

  const getColor = (percentage: number) => {
    if (percentage >= 60)
      return (theme) => theme.palette.figma.primary.highlight; // Orange
    if (percentage >= 21)
      return (theme) => theme.palette.figma.primary.yellow.third; // Yellow
    return (theme) => theme.palette.figma.validation.error; // Red
  };

  const getFontColor = (percentage: number) => {
    if (percentage >= 60)
      return (theme) => theme.palette.figma.secondary.orange.second;
    if (percentage >= 21)
      return (theme) => theme.palette.figma.primary.yellow.second;
    return (theme) => theme.palette.figma.validation.errorDark;
  };

  const barColor = getColor(remainingPercentage);
  const fontColor = getFontColor(remainingPercentage);

  return (
    <Box sx={{ width: "100%", mx: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography sx={{ fontWeight: 600 }}>
          {t("Profile.percentage_used")}
        </Typography>
        <Tooltip
          title={t("Profile.percentage_used_tooltip")}
          placement="top"
          arrow
          enterTouchDelay={0}
          leaveTouchDelay={2500}
        >
          <IconButton size="small" sx={{ ml: 1 }}>
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{
          height: 8,
          width: "100%",
          bgcolor: (theme) =>
            remainingPercentage <= 0
              ? theme.palette.figma.validation.errorLight3
              : "rgba(128, 128, 128, 0.1)",
          borderRadius: 4,
          position: "relative",
          overflow: "hidden",
          mb: 1,
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: `${remainingPercentage}%`,
            bgcolor: barColor,
            background: barColor,
            position: "absolute",
            transition: "width 0.5s ease-in-out",
          }}
        />
      </Box>
      <Typography variant="body1" sx={{ color: fontColor, fontWeight: 500 }}>
        {`${Math.round(remainingPercentage)}%`}
      </Typography>
    </Box>
  );
}
