import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Tooltip,
  IconButton,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { theme } from "../../../../theme.ts";
import guardianIcon from "../../../../assets/form_guardian.svg";
import heroIcon from "../../../../assets/form_hero.svg";
import realistIcon from "../../../../assets/form_realist.svg";
import creatorIcon from "../../../../assets/form_creator.svg";
import comedianIcon from "../../../../assets/form_comedian.svg";
import explorerIcon from "../../../../assets/form_explorer.svg";
import { ISocialMediaAssystentForm } from "../../../../models/social-media-assystent.ts";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import InfoIcon from "@mui/icons-material/Info";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const targets = [
  { name: "facebook", icon: <FacebookIcon sx={{ color: "#1877F2" }} /> },
  {
    name: "instagram",
    icon: (
      <InstagramIcon
        sx={{
          color: "#E4405F", // Instagram brand color
          "&:hover": {
            color: "#C13584", // A darker shade for hover effect
          },
        }}
      />
    ),
  },
];

export default function SecondStepVoice({ formValid, form, setForm, t }) {
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const archetypes = [
    {
      name: "guardian",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc1"),
      icon: guardianIcon,
    },
    {
      name: "hero",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc2"),
      icon: heroIcon,
    },
    {
      name: "realist",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc3"),
      icon: realistIcon,
    },
    {
      name: "creator",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc4"),
      icon: creatorIcon,
    },
    {
      name: "comedian",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc5"),
      icon: comedianIcon,
    },
    {
      name: "explorer",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc6"),
      icon: explorerIcon,
    },
  ];

  const hanfleSocialMediaTargetChange = (event: SelectChangeEvent<any>) => {
    setForm({
      ...form,
      socialMediaTarget: (event.target as HTMLInputElement).value,
    });
  };

  const isArchetypeActive = (name: string) => {
    return !!(
      (form as ISocialMediaAssystentForm).brandVoice.brandArchetype === name
    );
  };

  const handlebrandArchetype = (name: string) => {
    setForm({
      ...form,
      brandVoice: {
        brandArchetype: name,
        brandArchetypeDescription: archetypes.find((type) => type.name === name)
          ?.description,
      },
    });
  };

  const handlePostLength = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      postLength: (event.target as HTMLInputElement).value,
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box mt={5}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{
              color: "black",
              display: "flex",
              alignItems: "center",
            }} // Align icon with label text
          >
            {t("SMAsistant.Step2.postVoiceTitle")}
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3000}
              title={t("SMAsistant.Step2.postVoiceTooltip")}
            >
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </FormLabel>
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth>
            <Grid container spacing={1}>
              {archetypes.map((type, idx) => (
                <Grid item xs={12} sm={6} lg={4} key={type.name} mt={1.7}>
                  <Card
                    onClick={() => handlebrandArchetype(type.name)}
                    variant="outlined"
                    sx={[
                      {
                        height: "100%",
                        minHeight: "140px",
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                        border: "0px",
                        justifyContent: "center",
                        position: "relative", // Needed for the pseudo-element to position correctly
                        borderRadius: "8px", // Match the border radius of the Card
                        overflow: "hidden", // Ensure content stays within the border radius
                        transition: "background 0.5s",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",

                        // Pseudo-element for the gradient border
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          borderRadius: "8px",
                          padding: "3px", // This should match your border width
                          boxSizing: "border-box",
                          background:
                            "linear-gradient(0deg, rgba(255,197,77,1) 0%, rgba(237,112,105,1) 100%)",
                          WebkitMask:
                            "linear-gradient(#ffc54d 0 0) content-box, linear-gradient(#ffc54d 0 0)",
                          WebkitMaskComposite: "destination-out",
                          maskComposite: "exclude", // Compatibility for more browsers
                          pointerEvents: "none", // Ensure it doesn't interfere with clicks
                        },
                      },
                      isArchetypeActive(type.name) && {
                        background: theme.palette.figma.gradient.gradient5,
                      },
                    ]}
                  >
                    <CardContent>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {/* Left side: Icon */}
                        <img
                          src={type.icon}
                          alt="Icon"
                          style={{
                            width: "43px",
                            height: "43px",
                          }}
                        />

                        {/* Right side: Title and Description */}
                        <Box mt={1}>
                          <Typography
                            fontSize={"16px"}
                            sx={{ textTransform: "uppercase" }}
                          >
                            {t(
                              `SMAsistant.Step2.postVoiceArchetypeTitle${idx + 1}`
                            )}
                          </Typography>
                          <FormHelperText
                            sx={{
                              marginLeft: "0px",
                              fontSize: "14px",
                              color: "rgba(0, 0, 0, 1)",
                            }}
                          >
                            {type.description}
                          </FormHelperText>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {!formValid && !form.brandVoice.brandArchetype ? (
              <Box mt={1}>
                <Alert severity="error">{t("validators.fieldOligatory")}</Alert>
              </Box>
            ) : null}
          </FormControl>
        </Grid>
      </Grid>

      <Grid container mt={5} spacing={2}>
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "black" }}
          >
            {t("SMAsistant.Step2.platformTitle")}
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl sx={{ width: "100%" }}>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={form.socialMediaTarget}
              onChange={hanfleSocialMediaTargetChange}
              MenuProps={MenuProps}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9e9e9e", // Default border color
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9e9e9e", // Border color on hover
                  borderRadius: "8px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9e9e9e", // Border color when focused
                  borderRadius: "8px",
                },
              }}
            >
              {targets.map((platform) => (
                <MenuItem key={platform.name} value={platform.name}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {platform.name.charAt(0).toUpperCase() +
                      platform.name.slice(1)}
                    {platform.icon}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!formValid && !form.socialMediaTarget ? (
            <Box mt={1}>
              <Alert severity="error">{t("validators.fieldOligatory")}</Alert>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <Grid container mt={2} spacing={2}>
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "black" }}
          >
            {t("SMAsistant.Step2.postLenghtSelectionTitle")}
            <Tooltip
              enterTouchDelay={0}
              leaveTouchDelay={3300}
              title={t("SMAsistant.Step2.postLenghtSelectionTooltip")}
            >
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </FormLabel>
        </Grid>
        <Grid item md={12}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="brandTone"
              name="brandTone"
              value={form.postLength}
              onChange={handlePostLength}
            >
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FormControlLabel
                    value="short"
                    control={
                      <Radio
                        sx={{
                          color: "#424242",
                          "&.Mui-checked": {
                            color: theme.palette.figma.secondary.orange.second,
                          },
                        }}
                      />
                    }
                    label={t("SMAsistant.Step2.postLength1")}
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControlLabel
                    value="medium"
                    control={
                      <Radio
                        sx={{
                          color: "#424242",
                          "&.Mui-checked": {
                            color: theme.palette.figma.secondary.orange.second,
                          },
                        }}
                      />
                    }
                    label={t("SMAsistant.Step2.postLength2")}
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControlLabel
                    value="long"
                    control={
                      <Radio
                        sx={{
                          color: "#424242",
                          "&.Mui-checked": {
                            color: theme.palette.figma.secondary.orange.second,
                          },
                        }}
                      />
                    }
                    label={t("SMAsistant.Step2.postLength3")}
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControlLabel
                    value="dynamic"
                    control={
                      <Radio
                        color="secondary"
                        sx={{
                          color: "#424242",
                          "&.Mui-checked": {
                            color: theme.palette.figma.secondary.orange.second,
                          },
                        }}
                      />
                    }
                    label={t("SMAsistant.Step2.postLength4")}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
          {!formValid && !form.postLength ? (
            <Box mt={1}>
              <Alert severity="error">{t("validators.fieldOligatory")}</Alert>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
