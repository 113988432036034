import * as React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery,
  Paper,
  TextField,
} from "@mui/material";
import { theme } from "../../theme.ts";
import { useEffect, useState } from "react";
import {
  getAuthorizedUser,
  logoutAuthorizedUser,
} from "../../http/local-storage/auth/AuthProvider.ts";
import { axiosInstance } from "../../http/config/axiosInstance.ts";
import { baseURL } from "../../http/config/api.ts";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "../../components/LanguageSwitcher.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import UsageBar from "../../components/UsageBar.tsx";
import BrandCreateBanner from "../../components/BrandCreateBaner.tsx";

export interface UserUsage {
  uid: string;
  daysUntilReset: number;
  tokensUsage: number;
  percentageUsed: number;
}

function DeleteAccountDialog({ onClose, open, t }) {
  const handleClose = (deleteAccount: boolean) => {
    onClose(deleteAccount);
  };

  return (
    <Dialog onClose={() => handleClose(false)} open={open}>
      <DialogTitle>{t("Profile.delete_account_prompt")}</DialogTitle>

      <DialogContent>
        <Button
          onClick={() => handleClose(true)}
          color="error"
          variant="contained"
        >
          {t("actions.yes")}
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default function Profile({ t }) {
  const lessThanXL = useMediaQuery(theme.breakpoints.down("md"));
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const [userUsage, setUserUsage] = useState<UserUsage | null>(null);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const userToken = getAuthorizedUser()?.authorizedUser?.token;
    if (!userToken) {
      return;
    }
    axiosInstance
      .post<UserUsage>(`${baseURL}/user_panel/get_user_usage`, {
        idToken: userToken,
      })
      .then(({ data }) => {
        setIsLoading(false);
        setUserUsage(data);
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const deleteAccount = (deleteAccount: boolean) => {
    if (!deleteAccount) {
      setOpen(false);
      return;
    }

    const authorizedUser = getAuthorizedUser();
    const userToken = authorizedUser.authorizedUser.token;
    const userId = authorizedUser.id;

    return axiosInstance
      .delete(`${baseURL}/auth/delete_user`, {
        data: {
          id: userId,
          idToken: userToken,
        },
      })
      .then(() => {
        setOpen(false);
        logoutAuthorizedUser();
        navigate("/");
      });
  };

  return (
    <>
      <Box sx={{ mt: 4 }}>
        <BrandCreateBanner t={t} isXS={isXS} />
      </Box>
      <Box sx={{ px: 1, py: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              sx={{ mb: 3, fontWeight: 400, textTransform: "uppercase" }}
            >
              {t("Profile.settingTitle")}
            </Typography>
            <Paper
              elevation={0}
              sx={{
                p: 3,
                mb: 3,
                pb: 5,
                boxShadow: "0px 0px 4px 1px rgba(255, 215, 133, 0.5)",
              }}
            >
              <Box sx={{ mb: 3, mx: 1 }}>
                <Box sx={{ mb: 1, color: "black", fontWeight: 600 }}>
                  {t("Profile.profileNameField")}
                </Box>
                <TextField
                  fullWidth
                  disabled
                  value={getAuthorizedUser().authorizedUser.username || ""}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                  sx={{
                    borderRadius: "8px",
                    border: "solid 1px  black",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent !important", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent !important", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent !important", // Border color when focused
                      },
                    },
                  }}
                />
              </Box>
              <LanguageSwitcher t={t} />
            </Paper>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              disableElevation
              startIcon={<DeleteIcon />}
              sx={{
                px: 6,
                py: 1,
                backgroundColor: (theme) =>
                  theme.palette.figma.validation.errorLight3,
                color: "#000000",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#FFD4D4",
                },
              }}
            >
              {t("Profile.delete_account")}
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              sx={{ mb: 3, fontWeight: 400, textTransform: "uppercase" }}
            >
              {t("Profile.profileTitle")}
            </Typography>
            {!isLoading ? (
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  bgcolor: "#FFFFFF",
                  borderRadius: 2,
                  boxShadow: "0px 0px 4px 1px rgba(255, 215, 133, 0.5)",
                }}
              >
                <Box sx={{ mb: 3, mx: 1 }}>
                  <Box
                    sx={{
                      display: { xs: "block", sm: "flex" },
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      {t("Profile.emailField")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#666666",
                        fontWeight: 400,
                        ml: { xs: 0, sm: 2 },
                      }}
                    >
                      {getAuthorizedUser().authorizedUser.email}
                    </Typography>
                  </Box>
                </Box>
                <UsageBar
                  t={t}
                  percentageUsed={userUsage?.percentageUsed || 0}
                />
              </Paper>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size={24} color="secondary" />
              </Box>
            )}
          </Grid>
        </Grid>
        <DeleteAccountDialog open={open} onClose={deleteAccount} t={t} />
      </Box>
    </>
  );
}
