import * as React from "react";
import "./InstagramPost.css";
import {
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import InstagramPostContent from "./InstagramPostContent";
import FacebookForward from "../../../../assets/facebook_forward_arrow.png";
import FacebookThumb from "../../../../assets/facebook_thumbs_up_like.png";
import { theme } from "../../../../theme.ts";

const SocialIcon = ({ count, iconPath }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.8"
      stroke="currentColor"
      style={{ width: "24px", height: "24px" }}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d={iconPath} />
    </svg>
    {count ? <span>{count}</span> : ""}
  </Box>
);

const SocialIconImg = ({ img }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
    <img
      src={img}
      alt="img"
      className="icon-image-fb"
      style={{ width: "24px", height: "24px" }}
    />
  </Box>
);

const SVGIcon = ({ path }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.7"
    stroke="currentColor"
    style={{ width: "24px", height: "24px" }}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d={path} />
  </svg>
);

export default function InstagramPost({
  postText,
  setEditedText,
  generateImages,
  image,
  imageExtension,
  fromEditVariants,
  t,
  socialMediaTarget,
  regenerate,
  socialMedia,
  color,
  scoreText,
  scoreValue,
  index,
}) {
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const [text, setText] = React.useState("");
  const [edit, setEdit] = React.useState(false);

  const handlePopoverClose = () => {
    setAnchorEl(null); // Use the appropriate function here
  };

  useEffect(() => {
    setText(postText);

    window.addEventListener("scroll", (e) => handlePopoverClose());
  }, []);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const onSave = () => {
    setEditedText(text);
    setEdit(!edit);
    handlePopoverClose();
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [snackOpen, setSnackOpen] = React.useState(false);

  const handleClose = (event: React.SyntheticEvent | Event) => {
    setSnackOpen(false);
  };

  const handleDownloadImage = () => {
    const href = `data:${imageExtension};base64,${image}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = href;
    downloadLink.download = `image.${imageExtension}`;
    downloadLink.click();
  };

  const handleCopyToClipboard = () => {
    setSnackOpen(true);
    navigator.clipboard.writeText(text);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (event.currentTarget === anchorEl) {
      handlePopoverClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const open = Boolean(anchorEl);
  const MyParagraph = ({ children, ...props }) => (
    <div
      style={{ fontSize: "14px", fontWeight: "normal", whiteSpace: "pre-line" }}
      {...props}
    >
      {children}
    </div>
  );

  return (
    <>
      <Box
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={
          isXS
            ? undefined
            : socialMedia.isLoading || edit
              ? undefined
              : handlePopoverOpen
        }
        onMouseLeave={
          isXS
            ? undefined
            : socialMedia.isLoading
              ? undefined
              : handlePopoverClose
        }
        onClick={socialMedia.isLoading || edit ? undefined : handlePopoverOpen}
        sx={{ borderRadius: "18px" }}
      >
        <Box
          sx={{
            background: color,
            borderRadius: "16px",
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingLeft: "1.5px",
            paddingRight: "1.5px",
            flexDirection: "column", // Stack items vertically
            alignItems: "center",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          }}
          mt={2}
          mb={2}
        >
          <Box
            className={
              fromEditVariants
                ? ""
                : `generator-post-container ${open ? "overlay-active" : ""}`
            }
            sx={{
              backgroundColor: edit
                ? "rgba(0, 0, 0, 0.5)"
                : open && !fromEditVariants
                  ? "rgba(0, 0, 0, 0.5)"
                  : "white",
              borderRadius: "12px",
              width: "100%",
            }}
          >
            {!postText ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <Typography>
                  {generateImages
                    ? t("SMAsistant.Step5.loaderScorePost")
                    : !socialMedia.isEvaluating
                      ? t("SMAsistant.Step4.loaderScoreText")
                      : t("SMAsistant.Step4.loaderScoreText2")}
                </Typography>
                <Stack spacing={1} sx={{ width: "80%" }} mb={2} mt={1}>
                  {/* For variant="text", adjust the height via font-size */}
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={30}
                    sx={{
                      animation: "fast-pulse 1.6s ease-in-out infinite",
                      "@keyframes fast-pulse": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0.3 },
                        "100%": { opacity: 1 },
                      },
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={120}
                    sx={{
                      animation: "fast-pulse 1.6s ease-in-out infinite",
                      "@keyframes fast-pulse": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0.3 },
                        "100%": { opacity: 1 },
                      },
                    }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={110}
                    sx={{
                      animation: "fast-pulse 1.6s ease-in-out infinite",
                      "@keyframes fast-pulse": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0.3 },
                        "100%": { opacity: 1 },
                      },
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={40}
                    sx={{
                      animation: "fast-pulse 1.6s ease-in-out infinite",
                      "@keyframes fast-pulse": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0.3 },
                        "100%": { opacity: 1 },
                      },
                    }}
                  />
                </Stack>
              </Box>
            ) : (
              <>
                {generateImages && image && socialMediaTarget == "instagram" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "350px",
                        marginBottom: 9,
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          paddingTop: 5,
                          paddingLeft: "16px",
                          paddingRight: "16px",
                          objectFit: "cover",
                        }}
                        src={`data:${imageExtension};base64,${image}`}
                        alt=""
                      />
                    </div>
                  </Box>
                ) : (
                  ""
                )}
                {socialMediaTarget == "instagram" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: generateImages ? "0px" : "16px",
                      paddingRight: "16px",
                      paddingLeft: "16px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <SocialIcon
                        count={210}
                        iconPath="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                      />
                      <SocialIcon
                        count={7}
                        iconPath="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                      />
                      <SocialIcon
                        count={17}
                        iconPath="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                      />
                    </Box>
                    <Box>
                      <SVGIcon path="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                <React.StrictMode>
                  <InstagramPostContent
                    regenerate={regenerate}
                    t={t}
                    edit={edit}
                    text={text}
                    setEdit={setEdit}
                    onSave={onSave}
                    MyParagraph={MyParagraph}
                    fromEditVariants={fromEditVariants}
                    handleCopyToClipboard={handleCopyToClipboard}
                    handleDownloadImage={handleDownloadImage}
                    open={open}
                    handleChange={handleChange}
                    generateImages={generateImages}
                    postText={postText}
                    setText={setText}
                  />
                </React.StrictMode>

                {generateImages && image && socialMediaTarget == "facebook" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "350px",
                        marginBottom: 9,
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        src={`data:${imageExtension};base64,${image}`}
                        alt=""
                      />
                    </div>
                  </Box>
                ) : (
                  ""
                )}

                {socialMediaTarget == "facebook" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingBottom: "16px",
                      marginTop: "auto", // Push this Box to the bottom
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "space-evenly", // Distribute all icons evenly
                      }}
                    >
                      <SocialIconImg img={FacebookThumb} />
                      <SocialIcon
                        count={null}
                        iconPath="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                      />
                      <SocialIconImg img={FacebookForward} />
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
            mb={-1}
          >
            <Typography
              sx={{
                fontWeight: 400,
                mr: 1,
                color: index == 2 ? "#FFFFFF" : undefined,
              }}
            >
              {" "}
              {/* Add margin-right for spacing */}
              {scoreText}
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                color: index == 2 ? "#FFFFFF" : undefined,
              }}
            >
              {scoreValue + "%"}
            </Typography>
          </Box>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackOpen}
          autoHideDuration={1000}
          message={t("SMAsistant.InstagramPost.copyMessage")}
          onClose={handleClose}
        />
      </Box>
    </>
  );
}
