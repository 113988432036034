import * as React from "react";
import Box from "@mui/material/Box";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  RadialLinearScale,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  RadialLinearScale
);
ChartJS.defaults.font.family = "Lexend";
ChartJS.defaults.plugins.tooltip.enabled = false;

export default function RadarChart({ outputTexts, generateImages, t }) {
  const getTotalScore = (scoreGroups: { group: string; score: number }[]) => {
    const groupsSum = scoreGroups.reduce(
      (accumulator, group) => accumulator + group.score,
      0
    );
    return (groupsSum / scoreGroups.length).toFixed(2);
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          font: {
            family: "Lexend",
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            weight: 500, // Set font weight to bold
            family: "Lexend",
          },
        },
      },
    },
    y: {},

    animation: {
      duration: 2500, // Animation duration in milliseconds
      easing: "easeInOutQuart", // Smooth easing
      onProgress: (animation) => {
        const chartInstance = animation.chart;
        const { ctx, chartArea } = chartInstance;
        const { top, bottom, left, right } = chartArea;

        ctx.save();
        ctx.clearRect(left, top, right - left, bottom - top);
        chartInstance.draw();
        ctx.restore();
      },
    },
  };

  const translationsGroups = {
    action: t("SMAsistant.ScoreMapping.action"),
    content_brief_alignment: t(
      "SMAsistant.ScoreMapping.content_brief_alignment"
    ),
    content_quality: t("SMAsistant.ScoreMapping.content_quality"),
    target_group: t("SMAsistant.ScoreMapping.target_group"),
    technical_aspects: t("SMAsistant.ScoreMapping.technical_aspects"),
    image_score: t("SMAsistant.ScoreMapping.image_score"),
  };
  return (
    <Box sx={{ height: "448px" }}>
      <Line
        options={options}
        data={{
          labels: !outputTexts
            ? []
            : outputTexts[0].score?.scorePerGroup?.map(
                (g) => translationsGroups[g.group]
              ),
          datasets: [
            {
              label: !outputTexts
                ? ""
                : `${
                    generateImages
                      ? `${t("SMAsistant.PostScore.postScoreClean")}: ${getTotalScore(outputTexts[0].score.scorePerGroup)}`
                      : `${t("SMAsistant.PostScore.contentScoreClean")}: ${outputTexts[0].score.totalScore}`
                  } / 100`,
              data: !outputTexts
                ? []
                : outputTexts[0].score?.scorePerGroup?.map((g) => g.score),
              fill: true,
              backgroundColor: "#A5A7D1",
              borderColor: "#A5A7D1",
              pointBackgroundColor: "#A5A7D1",
              pointBorderColor: "#A5A7D1",
              pointHoverBackgroundColor: "#A5A7D1",
              pointHoverBorderColor: "#A5A7D1",
            },
            {
              label: !outputTexts
                ? ""
                : `${
                    generateImages
                      ? `${t("SMAsistant.PostScore.postScoreClean")}: ${getTotalScore(outputTexts[1].score.scorePerGroup)}`
                      : `${t("SMAsistant.PostScore.contentScoreClean")}: ${outputTexts[1].score.totalScore}`
                  } / 100`,
              data: !outputTexts
                ? []
                : outputTexts[1]?.score.scorePerGroup?.map((g) => g.score),
              fill: true,
              backgroundColor: "#FFC54D",
              borderColor: "#FFC54D",
              pointBackgroundColor: "#FFC54D",
              pointBorderColor: "#FFC54D",
              pointHoverBackgroundColor: "#FFC54D",
              pointHoverBorderColor: "#FFC54D",
            },
            {
              label: !outputTexts
                ? ""
                : `${
                    generateImages
                      ? `${t("SMAsistant.PostScore.postScoreClean")}: ${getTotalScore(outputTexts[2].score.scorePerGroup)}`
                      : `${t("SMAsistant.PostScore.contentScoreClean")}: ${outputTexts[2].score.totalScore}`
                  } / 100`,
              data: !outputTexts
                ? []
                : outputTexts[2]?.score.scorePerGroup?.map((g) => g.score),
              fill: true,
              backgroundColor: "#806227",
              borderColor: "#806227",
              pointBackgroundColor: "#806227",
              pointBorderColor: "#806227",
              pointHoverBackgroundColor: "#806227",
              pointHoverBorderColor: "#806227",
            },
          ],
        }}
      />
    </Box>
  );
}
