import * as React from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  useMediaQuery,
  Switch,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePexelImages,
  generateScore,
  SocialMediaState,
} from "../../../../http/store/reducers/social-media";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PostScore from "../components/PostScore.tsx";
import RadarChart from "../components/RadarChart.tsx";

import { getAuthorizedUser } from "../../../../http/local-storage/auth/AuthProvider";
import ScoreTable from "../components/ScoreTable.tsx";
import { theme } from "../../../../theme.ts";
import EditPostVariantsDialog from "../components/EditPostVariantsDialog.tsx";
import {
  IScoreOutputTextWithImagesInput,
  OutputTextAndImagesWithScore,
  TextScore,
} from "../../../../models/social-media-assystent.ts";
import uuid from "react-uuid";
import { scroller } from "react-scroll";
import { PexelsResponse } from "../../../../models/pexels.ts";
import PexelsLogo from "../../../../assets/pexels_logo.png";

export const FifthStepFullPost = ({
  inputData,
  setFormChanged,
  outputTexts,
  setOutputTexts,
  generateImages,
  setScoresChanged,
  scoresChanged,
  isPexels,
  setIsPexels,
  t,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXS = useMediaQuery("(max-width:1024px)");
  const lessThanXL = useMediaQuery("(max-width:1024px)");

  const [imagesDownloaded, setImagesDownloaded] = React.useState(false);
  const dispatch = useDispatch<any>();

  const [isTable, setIsTable] = useState(true);
  const [activeCurrentSlide, setActiveCurrentSlide] = useState<number>(
    isXS ? 0 : 2
  );

  const socialMedia = useSelector(
    (state: { socialMedia: SocialMediaState }) => state.socialMedia
  );

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const carouselRef: any = React.useRef();

  const defaultOutputTextAndImagesWithScore: OutputTextAndImagesWithScore = {
    postText: "",
    score: {
      rawScores: [],
      scorePerGroup: [],
      totalScore: 0,
    },
    image: undefined,
    imageExtension: undefined,
  };

  const setActivePost = (currentSlide) => {
    setActiveCurrentSlide(currentSlide);
  };

  const setActivePostFromCarousel = (previousSlide, currentSlide, dataSize) => {
    let activeSlide = 0;
    // right arrow
    if (previousSlide < currentSlide)
      activeSlide = currentSlide - 2 === dataSize ? 0 : currentSlide - 2;
    // left arrow
    else
      activeSlide =
        currentSlide +
        (currentSlide <= dataSize && currentSlide >= 2 ? -2 : dataSize - 2);
    setActiveCurrentSlide(activeSlide);
  };

  const sortPosts = (texts: OutputTextAndImagesWithScore[]) => {
    if (!texts.length) {
      return [];
    }
    const sortedOutputTexts = texts.sort(
      (a: OutputTextAndImagesWithScore, b: OutputTextAndImagesWithScore) => {
        return b.score.totalScore - a.score.totalScore;
      }
    );
    const firstPlaceAtSecondPosition = moveItem(sortedOutputTexts, 0, 1);
    return firstPlaceAtSecondPosition;
  };

  useEffect(() => {
    if (scoresChanged) {
      scorePostsImages(outputTexts);
    }
  }, []);

  const scoreImages = (
    outputTextAndImagesWithScoreCopy: OutputTextAndImagesWithScore[],
    inputImages: { imageBase64: string; imageExtension: string }[],
    outputTextAndImagesWithScore: OutputTextAndImagesWithScore[]
  ) => {
    setImagesDownloaded(true);
    try {
      const fetchCarouselPromises = outputTextAndImagesWithScoreCopy.map(
        (output) => {
          return Promise.all(
            inputImages.map(
              (
                imageObj: { imageBase64: string; imageExtension: string },
                imgIndex: number
              ) => {
                outputTextAndImagesWithScore.push({
                  ...output,
                  image: imageObj.imageBase64,
                  imageExtension: imageObj.imageExtension,
                });
                const userToken: string =
                  getAuthorizedUser().authorizedUser.token;
                const scoreInputData: IScoreOutputTextWithImagesInput = {
                  user_token: {
                    idToken: userToken,
                  },

                  score_image_input: {
                    image: {
                      imageBase64: imageObj.imageBase64,
                      imageExtension: imageObj.imageExtension,
                    },
                    contentBrief: inputData.llm_input.contentBrief,
                    text: output.postText,
                  },
                };
                return dispatch(generateScore(scoreInputData));
              }
            )
          );
        }
      );
      Promise.all(fetchCarouselPromises).then((values) => {
        const scores: TextScore[] = values.flat(1).map((v) => v.payload);
        sortOutputTextsWithScores(outputTextAndImagesWithScore, scores);
        setFormChanged(false);
        if (lessThanXL) {
          setTimeout(() => {
            carouselRef?.current?.next();
          }, 100);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const generateImagesAndScore = (
    outputTextsAndImagesWithScoreCopy: OutputTextAndImagesWithScore[],
    outputTexstAndImagesWithScore: OutputTextAndImagesWithScore[]
  ) => {
    const outputTextAndImageBestScore = outputTextsAndImagesWithScoreCopy.sort(
      (a: OutputTextAndImagesWithScore, b: OutputTextAndImagesWithScore) => {
        return b.score.totalScore - a.score.totalScore;
      }
    )[0];
    let userToken: string = getAuthorizedUser().authorizedUser.token;

    const generateImagesInput = {
      user_token: {
        idToken: userToken,
      },
      image_search_input: {
        postContent: outputTextAndImageBestScore.postText,
        maxNumImages: 3,
      },
    };
    dispatch(generatePexelImages(generateImagesInput)).then(({ payload }) => {
      const images = payload.map((pexelResponse: PexelsResponse) => ({
        image: pexelResponse.src.base64,
        imageExtension: pexelResponse.src.original
          .split("photos")[1]
          .split(".")[1],
      }));
      setIsPexels(true);
      setImagesDownloaded(true);

      try {
        const fetchCarouselPromises = outputTextsAndImagesWithScoreCopy.map(
          (output) => {
            return Promise.all(
              images.map(
                (
                  imageObj: { image: string; imageExtension: string },
                  imgIndex: number
                ) => {
                  outputTexstAndImagesWithScore.push({
                    ...output,
                    image: imageObj.image,
                    imageExtension: imageObj.imageExtension,
                  });
                  userToken = getAuthorizedUser().authorizedUser.token;
                  const scoreInputData = {
                    user_token: {
                      idToken: userToken,
                    },

                    score_image_input: {
                      image: {
                        imageBase64: imageObj.image,
                        imageExtension: imageObj.imageExtension,
                      },
                      contentBrief: inputData.llm_input.contentBrief,
                      text: output.postText,
                    },
                  };
                  return dispatch(generateScore(scoreInputData));
                }
              )
            );
          }
        );
        Promise.all(fetchCarouselPromises).then((values) => {
          const scores: TextScore[] = values.flat(1).map((v) => v.payload);
          sortOutputTextsWithScores(outputTexstAndImagesWithScore, scores);
          setFormChanged(false);
          if (lessThanXL) {
            setTimeout(() => {
              carouselRef?.current?.next();
            }, 100);
          }
        });
      } catch (err) {
        console.log(err);
      }
    });
  };

  const scorePostsImages = (
    outputTextAndImagesWithScore: OutputTextAndImagesWithScore[]
  ) => {
    const outputTextAndImagesWithScoreCopy = outputTextAndImagesWithScore;
    let outputTextsWithScoresAndImages: OutputTextAndImagesWithScore[] = [];

    setOutputTexts([
      defaultOutputTextAndImagesWithScore,
      defaultOutputTextAndImagesWithScore,
      defaultOutputTextAndImagesWithScore,
    ]);

    const inputImages: { imageBase64: string; imageExtension: string }[] =
      inputData.llm_input.images;

    if (!inputImages.length) {
      generateImagesAndScore(
        outputTextAndImagesWithScoreCopy,
        outputTextsWithScoresAndImages
      );
    } else {
      scoreImages(
        outputTextAndImagesWithScoreCopy,
        inputImages,
        outputTextsWithScoresAndImages
      );
    }
  };

  const getTotalScore = (
    scoreGroups: { group: string; score: number }[]
  ): number => {
    const groupsSum = scoreGroups.reduce(
      (accumulator: number, group) => accumulator + group.score,
      0
    );

    return +(groupsSum / scoreGroups.length).toFixed(2);
  };

  const sortOutputTextsWithScores = (
    outputTextsValue: OutputTextAndImagesWithScore[],
    scores: TextScore[]
  ): void => {
    const outputWithScores: OutputTextAndImagesWithScore[] = outputTextsValue
      .map((output: OutputTextAndImagesWithScore, outputIndex: number) => ({
        ...output,
        score: {
          ...output.score,
          totalScore: getTotalScore([
            ...output.score.scorePerGroup,
            ...(scores.find((score, scoreIndex) => scoreIndex === outputIndex)!
              .scorePerGroup as { group: string; score: number }[]),
          ]),
          scorePerGroup: [
            ...output.score.scorePerGroup,
            ...(scores.find((score, scoreIndex) => scoreIndex === outputIndex)!
              .scorePerGroup as { group: string; score: number }[]),
          ],
        } as TextScore,
      }))

      .sort(
        (a: OutputTextAndImagesWithScore, b: OutputTextAndImagesWithScore) => {
          return b.score.totalScore - a.score.totalScore;
        }
      );
    const firstPlaceAtSecondPosition = moveItem(outputWithScores, 0, 1);
    setOutputTexts(firstPlaceAtSecondPosition);
    setScoresChanged(false);
  };

  const moveItem = (
    arr: OutputTextAndImagesWithScore[],
    fromIndex: number,
    toIndex: number
  ) => {
    const newArr = [...arr];
    newArr.splice(toIndex, 0, newArr.splice(fromIndex, 1)[0]);
    return newArr;
  };

  const variantEdit = () => {
    setOpen(true);
  };

  const handleToggle = (event) => {
    setIsTable(event.target.checked);
    scroller.scrollTo("title-analysis-generator", {
      duration: 5,
      smooth: false,
    });
  };

  const handleArrowClick = (direction) => {
    if (direction > 0) {
      carouselRef.current.next();
    } else {
      carouselRef.current.previous();
    }
  };
  const dots = [0, 1, 2];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box>
      {socialMedia.isError ? (
        <Box mt={1}>
          <Alert severity="error">{t("validators.somethingWrong")}</Alert>
        </Box>
      ) : (
        ""
      )}
      {socialMedia.tokensExpired ? (
        <Box mt={1}>
          <Alert severity="error">{t("validators.tokensExpired")}</Alert>
        </Box>
      ) : (
        ""
      )}
      {!outputTexts?.length &&
      !socialMedia.isError &&
      !socialMedia.tokensExpired ? (
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {!inputData.llm_input.images.length &&
            !imagesDownloaded &&
            t("SMAsistant.Step5.pexelDownload")}
          {imagesDownloaded && t("SMAsistant.Step5.loaderScorePost")}
          <CircularProgress
            size="1.5rem"
            sx={{ marginLeft: "1.5rem" }}
            disableShrink
            color="secondary"
          />
        </Grid>
      ) : (
        <Grid item md={12}>
          {lessThanXL ? (
            <Box
              sx={{
                display: "grid",
              }}
              mb={2}
            >
              <Carousel
                ref={carouselRef}
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={false}
                showDots={true}
                infinite={true}
                partialVisible={false}
                dotListClass="custom-dot-list-style"
                containerClass="carousel-container"
                itemClass="p-3"
                afterChange={(previousSlide, active) => {
                  setActivePostFromCarousel(
                    previousSlide,
                    active.currentSlide,
                    3
                  );
                }}
                className="fifth-step-carousel"
              >
                {sortPosts(outputTexts.filter((_, index) => index < 3)).map(
                  (
                    outputTextWithScore: OutputTextAndImagesWithScore,
                    index: number
                  ) => {
                    return (
                      <PostScore
                        outputTextWithScore={outputTextWithScore}
                        index={index}
                        setEditedText={null}
                        generateImages={generateImages}
                        t={t}
                        fromEditVariants={false}
                        socialMediaTarget={
                          inputData.llm_input.contentBrief.socialMediaTarget
                        }
                        regenerate={null}
                        socialMedia={socialMedia}
                      />
                    );
                  }
                )}
              </Carousel>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {sortPosts(outputTexts).map(
                (
                  outputTextWithScore: OutputTextAndImagesWithScore,
                  index: number
                ) => {
                  return (
                    index < 3 && (
                      <Grid
                        key={uuid()}
                        item
                        md={4}
                        onClick={() => setActivePost(index)}
                      >
                        <PostScore
                          outputTextWithScore={outputTextWithScore}
                          index={index}
                          setEditedText={null}
                          generateImages={generateImages}
                          t={t}
                          fromEditVariants={false}
                          socialMediaTarget={
                            inputData.llm_input.contentBrief.socialMediaTarget
                          }
                          regenerate={null}
                          socialMedia={socialMedia}
                        />
                      </Grid>
                    )
                  );
                }
              )}
            </Grid>
          )}
        </Grid>
      )}
      {isPexels && outputTexts[0]?.postText ? (
        <Typography
          component="div"
          mt={lessThanXL ? -1 : 0}
          style={{ fontSize: "0.6rem", display: "flex", alignItems: "center" }}
        >
          {t("SMAsistant.Step5.pexels")}{" "}
          <a
            href="https://www.pexels.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "inherit",
              textDecoration: "none",
              marginLeft: "4px",
            }}
          >
            Pexels
          </a>
          <img
            src={PexelsLogo}
            alt="pexelslogo"
            style={{
              width: "20px",
              marginLeft: "4px",
            }}
          />
        </Typography>
      ) : (
        ""
      )}
      {outputTexts[0]?.postText ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Button
            sx={{
              background: (theme) => theme.palette.gradient.toTop,
              marginLeft: lessThanXL ? "20px" : undefined,
              marginRight: lessThanXL ? "20px" : undefined,
            }}
            onClick={() => variantEdit()}
            color="secondary"
            variant="contained"
            fullWidth={lessThanXL ? true : false}
          >
            {t("SMAsistant.Step5.variantEdit")}
          </Button>
        </Box>
      ) : (
        ""
      )}

      <Grid container spacing={2}>
        <Grid
          item
          md={12}
          mt={2}
          sx={{
            display: "flex",
            textAlign: "center",
            padding: "0",
            justifyContent: "center",
          }}
        >
          <EditPostVariantsDialog
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            outputTexts={outputTexts}
            setEditedText={null}
            generateImages={generateImages}
            socialMediaTarget={
              inputData.llm_input.contentBrief.socialMediaTarget
            }
            t={t}
            socialMedia={socialMedia}
          />
        </Grid>
      </Grid>

      <Grid container mt={2} spacing={2}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            textAlign: "center",
            padding: "0",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {outputTexts[0]?.postText ? (
            <>
              {/* Title on the left */}
              <Typography
                id="title-analysis-generator"
                sx={{
                  fontWeight: "bold",
                  opacity: 0.3,
                  paddingLeft: 2, // Optional padding for left alignment
                }}
              >
                {t("SMAsistant.Step5.analysisTitle")}
              </Typography>

              <Box>
                {/* Conditionally render the Toggle Switch only if not extra small screen */}
                {!isXS && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: 2,
                    }}
                  >
                    {/* Left label */}
                    <Typography
                      variant="body1"
                      sx={{
                        mr: 1,
                        color: !isTable ? "black" : "grey",
                        fontWeight: !isTable ? "bold" : "normal",
                      }}
                    >
                      {t("SMAsistant.Step4.tabPlot")}
                    </Typography>

                    <Switch
                      checked={isTable}
                      onChange={handleToggle}
                      inputProps={{ "aria-label": "Chart or Table switch" }}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: theme.palette.figma.secondary.orange.second,
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor:
                              theme.palette.figma.secondary.orange.second,
                          },
                        "& .MuiSwitch-track": {
                          backgroundColor: "grey",
                        },
                      }}
                    />

                    {/* Right label */}
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        color: isTable ? "black" : "grey",
                        fontWeight: isTable ? "bold" : "normal",
                      }}
                    >
                      {t("SMAsistant.Step4.tabTable")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          ) : null}
        </Grid>

        <Grid
          item
          md={12}
          sx={{
            display: isTable ? "flex" : undefined,
            textAlign: "center",
            padding: "0",
            justifyContent: "center",
          }}
        >
          {outputTexts[0]?.postText ? (
            <Card
              sx={{ boxShadow: 3, borderRadius: "8px", position: "relative" }}
            >
              <CardContent>
                <Box>
                  {!isTable ? (
                    <Box>
                      <RadarChart
                        generateImages={generateImages}
                        outputTexts={sortPosts(outputTexts)}
                        t={t}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ width: "100%" }}>
                      <ScoreTable
                        outputTexts={sortPosts(outputTexts)}
                        activeCurrentSlide={activeCurrentSlide}
                        t={t}
                      />
                    </Box>
                  )}

                  {isMobile && (
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        top: "50%",
                        left: 0, // Align the left arrow to the left
                        right: 0, // Align the right arrow to the right
                        transform: "translateY(-50%)", // Vertically center the arrows
                        justifyContent: "space-between", // Space out the arrows to the left and right
                        alignItems: "center", // Vertically center the arrows
                        width: "100%",
                      }}
                    >
                      <IconButton
                        onClick={() => handleArrowClick(-2)}
                        aria-label="previous slide"
                      >
                        <ArrowBackIosNewIcon
                          sx={{
                            fontWeight: 900,
                            fontSize: "2rem",
                            color: "black",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => handleArrowClick(1)}
                        aria-label="next slide"
                        disabled={activeCurrentSlide == 3}
                      >
                        <ArrowForwardIosIcon
                          sx={{
                            fontWeight: 900,
                            fontSize: "2rem",
                            color: "black",
                          }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            width: "100%",
          }}
        >
          {isMobile && outputTexts[0]?.postText ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                width: "100%",
              }}
            >
              {dots.map((dot, index) => (
                <Box
                  key={index}
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    backgroundColor:
                      index === activeCurrentSlide ? "orange" : "#ddd",
                  }}
                />
              ))}
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};
