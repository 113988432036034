import { createTheme } from "@mui/material/styles";

const fontFamily = ["Lexend", "sans-serif"].join(",");

declare module "@mui/material/styles" {
  interface PaletteOptions {
    gradient: {
      main: string;
      mainGradient: string;
      mainChannel: string;
      mainGradientReversed: string;
      mainGradientZero: string;
      toTop: string;
      toTopChannel: string;
      dark: string;
      darkChannel: string;
      light: string;
      lightChannel: string;
      contrastText: string;
      contrastTextChannel: string;
      gold?: string;
      silver?: string;
      brown?: string;
    };
    dashboard: {
      rand: string;
    };
    figma: {
      primary: {
        highlight: string;
        yellow: {
          first: string;
          second: string;
          third: string;
          fourth: string;
          fifth: string;
        };
      };
      secondary: {
        orange: {
          first: string;
          second: string;
          third: string;
        };
      };
      gradient: {
        gradient1: string;
        gradient2: string;
        gradient3: string;
        gradient4: string;
        gradient5: string;
        gradient6: string;
      };
      validation: {
        positive: string;
        errorLight3: string;
        error: string;
        errorDark: string;
      };
    };
  }

  interface Palette {
    gradient: {
      main: string;
      mainGradient: string;
      mainGradientReversed: string;
      mainGradientZero: string;
      toTop: string;
      dark: string;
      light: string;
      contrastText: string;
      gold?: string;
      silver?: string;
      brown?: string;
    };
    dashboard: {
      rand: string;
    };
    figma: {
      highlight: string;
      primary: {
        yellow: {
          first: string;
          second: string;
          third: string;
          fourth: string;
          fifth: string;
        };
      };
      secondary: {
        orange: {
          first: string;
          second: string;
          third: string;
        };
      };
      gradient: {
        gradient1: string;
        gradient2: string;
        gradient3: string;
        gradient4: string;
        gradient5: string;
        gradient6: string;
      };
      validation: {
        positive: string;
        errorLight3: string;
        error: string;
        errorDark: string;
      };
    };
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    h1: { fontFamily },
    h2: { fontFamily },
    h3: { fontFamily },
    h4: { fontFamily },
    h5: { fontFamily },
    h6: { fontFamily },
    subtitle1: { fontFamily },
    subtitle2: { fontFamily },
    body1: { fontFamily },
    body2: { fontFamily },
    button: { fontFamily, fontWeight: "bold" },
    caption: { fontFamily },
    overline: { fontFamily },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#130021",
      contrastText: "#fff",
    },
    secondary: {
      main: "#FFD785",
      contrastText: "#130021",
    },

    gradient: {
      main: "#130021",
      mainGradient:
        "linear-gradient(0deg, rgba(250,133,126,1) 0%, rgba(255,197,77,1) 100%)",
      mainGradientReversed:
        "linear-gradient(180deg, #FF6663 0%, #FFC857 100%);",
      mainGradientZero: "linear-gradient(0deg, #FF6663 0%, #FFC857 100%);",
      mainChannel: "0 0 0",
      toTop:
        "linear-gradient(to top,  #FF6663  0%, #FFC857  100%), linear-gradient(180deg, #FF6663  0%, #FFC857 100%);",
      toTopChannel: "0 0 0",
      dark: "linear-gradient(to top,  #130021  0%, #1d0133  100%), linear-gradient(180deg, #130021  0%, #1d0133  100%)",
      darkChannel: "0 0 0",
      light: "#FFD785",
      lightChannel: "0 0 0",
      contrastText: "#fff",
      contrastTextChannel: "0 0 0",
      gold: ` linear-gradient(-83deg, rgba(179,138,54,1) 0%, rgba(255,214,130,1) 31%, rgba(255,214,130,1) 56%, rgba(179,138,54,1) 100%)`,
      silver: `linear-gradient(-83deg, rgba(114,121,148,1) 0%, rgba(209,229,244,1) 33%, rgba(209,229,244,1) 67%, rgba(114,121,148,1) 100%)`,
      brown: `linear-gradient(-83deg, rgba(90,67,21,1) 0%, rgba(123,93,33,1) 33%, rgba(123,93,33,1) 67%, rgba(90,67,21,1)  100%)`,
    },
    dashboard: {
      rand: "#ED7069",
    },

    // Figma Colors - I'm keeping it like this, since unifying colors will probably take a while
    figma: {
      primary: {
        highlight: "linear-gradient(0deg, #FA9991 0%, #FDCD69 100%)",
        yellow: {
          first: "#806227",
          second: "#B38A36",
          third: "#FFC54D",
          fourth: "#FFD682",
          fifth: "#FFE8B8",
        },
      },
      secondary: {
        orange: {
          first: "#975C34",
          second: "#FB9957",
          third: "#DB5350",
        },
      },
      gradient: {
        gradient1:
          "linear-gradient(-83deg, rgba(250,133,126,1) 0%, rgba(255,197,77,1) 100%)",
        gradient2: "linear-gradient(-39deg, #FF6663 0%, #FB9957 100%)",
        gradient3:
          "linear-gradient(180deg, rgba(255,232,185,1) 70%, rgba(255,255,255,1) 100%)",
        gradient4:
          "linear-gradient(0deg, rgba(255,232,184,1) 0%, rgba(255,226,207,1) 100%)",
        gradient5:
          "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,226,165,1) 100%)",
        gradient6:
          "linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(237,237,237,1) 86%)",
      },
      validation: {
        errorLight3: "#F9D4D7",
        error: "#DF2935",
        errorDark: "#7a171e",
        positive: "#1DA412",
      },
    },
  },
});

export const darkTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    h1: { fontFamily },
    h2: { fontFamily },
    h3: { fontFamily },
    h4: { fontFamily },
    h5: { fontFamily },
    h6: { fontFamily },
    subtitle1: { fontFamily },
    subtitle2: { fontFamily },
    body1: { fontFamily },
    body2: { fontFamily },
    button: { fontFamily, fontWeight: "bold" },
    caption: { fontFamily },
    overline: { fontFamily },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#fff",
      contrastText: "#000",
    },
    secondary: {
      main: "#FFD785",
      contrastText: "#000",
    },
    gradient: {
      main: "#130021",
      mainGradient:
        "linear-gradient(0deg, rgba(250,133,126,1) 0%, rgba(255,197,77,1) 100%)",
      mainGradientReversed:
        "linear-gradient(180deg, #FF6663 0%, #FFC857 100%);",
      mainGradientZero: "linear-gradient(0deg, #FF6663 0%, #FFC857 100%);",
      mainChannel: "0 0 0",
      toTop:
        "linear-gradient(to top,  #FF6663  0%, #FFC857  100%), linear-gradient(180deg, #FF6663  0%, #FFC857 100%);",
      toTopChannel: "0 0 0",
      dark: "linear-gradient(to top,  #130021  0%, #1d0133  100%), linear-gradient(180deg, #130021  0%, #1d0133  100%)",
      darkChannel: "0 0 0",
      light: "#272729",
      lightChannel: "0 0 0",
      contrastText: "#fff",
      contrastTextChannel: "0 0 0",
    },
    dashboard: {
      rand: "#ED7069",
    },

    figma: {
      primary: {
        highlight: "linear-gradient(0deg, #FA9991 0%, #FDCD69 100%)",
        yellow: {
          first: "#806227",
          second: "#B38A36",
          third: "#FFC54D",
          fourth: "#FFD682",
          fifth: "#FFE8B8",
        },
      },
      secondary: {
        orange: {
          first: "#975C34",
          second: "#FB9957",
          third: "#DB5350",
        },
      },
      gradient: {
        gradient1:
          "linear-gradient(-83deg, rgba(250,133,126,1) 0%, rgba(255,197,77,1) 100%)",
        gradient2: "linear-gradient(-39deg, #FF6663 0%, #FB9957 100%)",
        gradient3:
          "linear-gradient(180deg, rgba(255,232,185,1) 70%, rgba(255,255,255,1) 100%)",
        gradient4:
          "linear-gradient(180deg, rgba(255,232,184,1) 0%, rgba(255,226,207,1) 100%)",
        gradient5:
          "linear-gradient(180deg, white 0%, rgba(255,197,77,0.5) 50%)",
        gradient6:
          "linear-gradient(0deg, rgba(251,251,251,1) 0%, rgba(237,237,237,1) 86%)",
      },
      validation: {
        positive: "#1DA412",
        errorLight3: "#F9D4D7",
        error: "#DF2935",
        errorDark: "#7a171e",
      },
    },
  },
});
