import React from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { changeCurrentLang } from "../http/store/reducers/config";
import { setLang } from "../http/local-storage/config/LangProvider";
import { useDispatch } from "react-redux";

export default function LanguageSwitcher({ t, minimal = false }) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch<any>();

  const handleChange = (event: SelectChangeEvent) => {
    const selectedLanguage = event.target.value;
    dispatch(changeCurrentLang(selectedLanguage)).then(() => {
      i18n.changeLanguage(selectedLanguage);
      setLang(selectedLanguage);
    });
  };

  return (
    <Box sx={{ mx: 1 }}>
      <FormControl fullWidth>
        {!minimal && (
          <Box sx={{ mb: 1, color: "black", fontWeight: 600 }}>
            {t("lang.langTitle")}
          </Box>
        )}
        <Select
          value={i18n.language}
          onChange={handleChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              border: minimal ? "none" : "1px solid black",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: minimal ? "transparent" : "black",
                borderRadius: "8px",
                border: minimal ? "none" : "1px solid black",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: minimal ? "transparent" : "black",
                borderRadius: "8px",
                border: minimal ? "none" : "1px solid black",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "8px",
              border: minimal ? "none" : "1px solid black",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: minimal ? "transparent" : "black",
              borderRadius: "8px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: minimal ? "transparent" : "black",
              border: minimal ? "none" : "1px solid black",
              borderRadius: "8px",
            },
          }}
        >
          <MenuItem value="pl">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mt: minimal ? 0.5 : 0,
              }}
            >
              <img
                loading="lazy"
                width={minimal ? "30" : "20"}
                height={minimal ? "30" : "auto"}
                src={`https://flagcdn.com/w40/pl.png`}
                srcSet={`https://flagcdn.com/w40/pl.png 2x`}
                alt="Polish flag"
                style={{
                  boxShadow: minimal
                    ? "0px 0px 4px rgba(0, 0, 0, 0.3)"
                    : "none",
                }}
              />
              {!minimal && "Polski"}
            </Box>
          </MenuItem>
          <MenuItem value="en">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mt: minimal ? 0.5 : 0,
              }}
            >
              <img
                loading="lazy"
                width={minimal ? "30" : "20"}
                height={minimal ? "30" : "auto"}
                src={`https://flagcdn.com/w40/gb.png`}
                srcSet={`https://flagcdn.com/w40/gb.png 2x`}
                alt="British flag"
                style={{
                  boxShadow: minimal
                    ? "0px 0px 4px rgba(0, 0, 0, 0.3)"
                    : "none",
                }}
              />
              {!minimal && "English"}
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
