import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Tooltip,
  IconButton,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Slider,
  useMediaQuery,
  TextField,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { theme } from "../../../../theme.ts";
import { PiGenderFemale } from "react-icons/pi";
import { PiGenderMale } from "react-icons/pi";
import { PiGenderIntersex } from "react-icons/pi";
import { GB } from "country-flag-icons/react/3x2";
import { PL } from "country-flag-icons/react/3x2";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const languages = [
  {
    name: "polski",
    icon: <PL />,
  },
  { name: "english", icon: <GB /> },
];

export default function ThirdStepAudience({ formValid, form, setForm, t }) {
  const isXS = useMediaQuery(theme.breakpoints.down("lg"));
  const handleGender = (event: SelectChangeEvent) => {
    setForm({
      ...form,
      targetAudience: [
        {
          ...form.targetAudience[0],
          gender: (event.target as HTMLInputElement).value,
        },
      ],
    });
  };

  const handleAgeRange = (event) => {
    setForm({
      ...form,
      targetAudience: [
        {
          ...form.targetAudience[0],
          ageRange: (event.target as HTMLInputElement).value,
        },
      ],
    });
  };

  const handleChangeLanguage = (event: SelectChangeEvent<any>) => {
    setForm({
      ...form,
      language: (event.target as HTMLInputElement).value,
    });
  };

  const handleOtherTraits = (event) => {
    setForm({
      ...form,
      targetAudience: [
        {
          ...form.targetAudience[0],
          otherTraits: (event.target as HTMLInputElement).value,
        },
      ],
    });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box mt={5}>
      <Grid container mt={2} spacing={2}>
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "black" }}
          >
            {t("SMAsistant.Step3.genderTitle")}
          </FormLabel>
        </Grid>
        <Grid item md={12}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="gender"
              name="gender"
              value={form.targetAudience[0].gender}
              onChange={handleGender}
            >
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <FormControlLabel
                    value="all"
                    control={
                      <Radio
                        sx={{
                          color: "#424242",
                          "&.Mui-checked": {
                            color: theme.palette.figma.secondary.orange.second,
                          },
                        }}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        {t("SMAsistant.Step3.genderOption3")}
                        <PiGenderIntersex
                          style={{
                            color: theme.palette.figma.secondary.orange.second,
                            width: 21,
                            height: 21,
                            marginLeft: 5,
                          }}
                        />
                      </Box>
                    }
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio
                        sx={{
                          color: "#424242",
                          "&.Mui-checked": {
                            color: theme.palette.figma.secondary.orange.second,
                          },
                        }}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        {t("SMAsistant.Step3.genderOption1")}
                        <PiGenderFemale
                          style={{
                            color: theme.palette.figma.secondary.orange.second,
                            width: 21,
                            height: 21,
                            marginLeft: 5,
                          }}
                        />
                      </Box>
                    }
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControlLabel
                    value="male"
                    control={
                      <Radio
                        sx={{
                          color: "#424242",
                          "&.Mui-checked": {
                            color: theme.palette.figma.secondary.orange.second,
                          },
                        }}
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center">
                        {t("SMAsistant.Step3.genderOption2")}
                        <PiGenderMale
                          style={{
                            color: theme.palette.figma.secondary.orange.second,
                            width: 21,
                            height: 21,
                            marginLeft: 5,
                          }}
                        />
                      </Box>
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
          {!formValid && !form.targetAudience[0].gender ? (
            <Box mt={1}>
              <Alert severity="error">{t("validators.fieldOligatory")}</Alert>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <Grid container mt={2} spacing={2}>
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "black" }}
          >
            {t("SMAsistant.Step3.ageTitle")}{" "}
            {`${form.targetAudience[0].ageRange[0]} - ${form.targetAudience[0].ageRange[1]}`}
          </FormLabel>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ marginLeft: "8px", marginRight: "8px" }}
        >
          <Slider
            sx={{
              width: "100%",
              color: theme.palette.figma.secondary.orange.second,
            }}
            step={1}
            min={16}
            value={form.targetAudience[0].ageRange}
            onChange={handleAgeRange}
            valueLabelDisplay="auto"
          />
        </Grid>
      </Grid>

      <Grid container mt={2} spacing={2}>
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "black" }}
          >
            {t("SMAsistant.Step3.otherTraitsTitle")}
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            multiline
            value={form.targetAudience[0].otherTraits}
            onChange={handleOtherTraits}
            rows={isXS ? 4 : 2}
            sx={{
              width: "100%",
              borderRadius: "8px",
              border: "solid 1px  #9e9e9e",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "transparent", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "transparent", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent", // Border color when focused
                },
              },
            }}
            id="outlined-basic"
            placeholder={t("SMAsistant.Step3.otherTraitsPlaceholder")}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={t("SMAsistant.Step3.otherTraitsTooltip")}
                  enterTouchDelay={0}
                  leaveTouchDelay={2500}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
          {!formValid && !form.targetAudience[0].otherTraits ? (
            <Box mt={1}>
              <Alert severity="error">{t("validators.fieldOligatory")}</Alert>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <Grid container mt={2} spacing={2}>
        <Grid item md={12}>
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ color: "black" }}
          >
            {t("SMAsistant.Step3.languageTitle")}
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl sx={{ width: "100%" }}>
            <Select
              labelId="language"
              id="language"
              value={form.language}
              onChange={handleChangeLanguage}
              MenuProps={MenuProps}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9e9e9e", // Default border color
                  borderRadius: "8px",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9e9e9e", // Border color on hover
                  borderRadius: "8px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#9e9e9e", // Border color when focused
                  borderRadius: "8px",
                },
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang.name} value={lang.name}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {lang.icon}
                    {lang.name.charAt(0).toUpperCase() + lang.name.slice(1)}
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!formValid && !form.language ? (
            <Box mt={1}>
              <Alert severity="error">{t("validators.fieldOligatory")}</Alert>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
