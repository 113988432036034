import * as React from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Grid,
  IconButton,
  useMediaQuery,
  Switch,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generateScore,
  generateSocialMedia,
  SocialMediaState,
} from "../../../../http/store/reducers/social-media";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PostScore from "../components/PostScore";
import RadarChart from "../components/RadarChart";

import { getAuthorizedUser } from "../../../../http/local-storage/auth/AuthProvider";
import ScoreTable from "../components/ScoreTable.tsx";
import { theme } from "../../../../theme.ts";
import {
  IScoreOutputTextInput,
  OutputText,
  OutputTextWithScore,
  TextScore,
} from "../../../../models/social-media-assystent.ts";
import uuid from "react-uuid";
import { scroller } from "react-scroll";

export const FourthStepView = ({
  inputData,
  formChanged,
  setFormChanged,
  outputTexts,
  setOutputTexts,
  generateImages,
  t,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXS = useMediaQuery("(max-width:1024px)");
  const lessThanXL = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch<any>();

  const [activeCurrentSlide, setActiveCurrentSlide] = useState<number>(
    isXS ? 0 : 1
  );

  const [isTable, setIsTable] = useState(true);

  const socialMedia = useSelector(
    (state: { socialMedia: SocialMediaState }) => state.socialMedia
  );
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const carouselRef: any = useRef();

  const defaultOutputTextWithScore: OutputTextWithScore = {
    postText: "",
    score: {
      rawScores: [],
      scorePerGroup: [
        { group: "action", score: 0 },
        { group: "content_brief_alignment", score: 0 },
        { group: "content_quality", score: 0 },
        { group: "target_group", score: 0 },
        { group: "technical_aspects", score: 0 },
      ],
      totalScore: 0,
    },
  };

  const onSetEditedText = (text: any, editedPostIndex: number) => {
    scorePost(text, editedPostIndex);
  };

  const moveItem = (
    outputTextsWithScores: OutputTextWithScore[],
    fromIndex: number,
    toIndex: number
  ) => {
    const outputTextsWithScopresCopy = [...outputTextsWithScores];
    outputTextsWithScopresCopy.splice(
      toIndex,
      0,
      outputTextsWithScopresCopy.splice(fromIndex, 1)[0]
    );
    return outputTextsWithScopresCopy;
  };

  const setActivePost = (previousSlide, currentSlide, dataSize): void => {
    let activeSlide = 0;
    // right arrow
    if (previousSlide < currentSlide)
      activeSlide = currentSlide - 2 === dataSize ? 0 : currentSlide - 2;
    // left arrow
    else
      activeSlide =
        currentSlide +
        (currentSlide <= dataSize && currentSlide >= 2 ? -2 : dataSize - 2);
    setActiveCurrentSlide(activeSlide);
  };

  const regenerate = (editedPostIndex: number) => {
    const regenerateInput = {
      ...inputData,
      llm_input: {
        ...inputData.llm_input,
        numTxtGenerate: 1,
      },
    };
    // we do it as real index is different then sorted
    const idxPost = decodeIndex(editedPostIndex);
    setOutputTexts((prevOutputTexts) =>
      prevOutputTexts.map((item, index) =>
        index === idxPost
          ? {
              ...defaultOutputTextWithScore,
              score: {
                ...defaultOutputTextWithScore.score,
                totalScore: item.score?.totalScore || 0, // Fallback to 0 if totalScore is undefined
              },
            }
          : item
      )
    );
    dispatch(generateSocialMedia(regenerateInput)).then(({ payload }) => {
      if (!payload) {
        return;
      }
      const outputTextsCopy: OutputTextWithScore[] = [
        ...sortPosts(outputTexts),
      ];

      const outputText: string = (payload.outputTexts[0] as OutputText)
        .postText;
      scorePost(outputText, editedPostIndex, outputTextsCopy);
    });
  };
  const decodeIndex = (n) => {
    if (n === 1) return 0;
    if (n === 0) return 1;
    if (n === 2) return 2;
    return n; // Default case if n is not 0, 1, or 2
  };

  const scorePost = (
    editedText: string,
    editedPostIndex: number,
    outputTextsCopyFromGenerate?: OutputTextWithScore[]
  ) => {
    const outputTextsCopy: OutputTextWithScore[] = outputTextsCopyFromGenerate
      ? outputTextsCopyFromGenerate
      : [...sortPosts(outputTexts)];

    // we do it as real index is different then sorted
    const idxPost = decodeIndex(editedPostIndex);
    setOutputTexts((prevOutputTexts) =>
      prevOutputTexts.map((item, index) =>
        index === idxPost
          ? {
              ...defaultOutputTextWithScore,
              score: {
                ...defaultOutputTextWithScore.score,
                totalScore: item.score?.totalScore || 0, // Fallback to 0 if totalScore is undefined
              },
            }
          : item
      )
    );

    const userToken = getAuthorizedUser().authorizedUser.token;
    const activeIndex = editedPostIndex;
    try {
      const scoreInputData: IScoreOutputTextInput = {
        user_token: {
          idToken: userToken,
        },
        score_text_input: {
          text: editedText,
          contentBrief: inputData.llm_input.contentBrief,
          score_image_input: null,
        },
      };
      return dispatch(generateScore(scoreInputData)).then((value) => {
        const outputTextsWithNewScore: OutputTextWithScore[] = [
          ...outputTextsCopy.map((outputWithScore, i) => {
            if (i === activeIndex) {
              return {
                ...outputWithScore,
                postText: editedText,
                score: value.payload,
              };
            } else {
              return outputWithScore;
            }
          }),
        ];
        const outputTextsWithScoresSorted: OutputTextWithScore[] =
          outputTextsWithNewScore.sort((a, b) => {
            return b.score.totalScore - a.score.totalScore;
          });

        const firstPlaceAtSecondPosition: OutputTextWithScore[] = moveItem(
          outputTextsWithScoresSorted,
          0,
          1
        );

        setOutputTexts([...firstPlaceAtSecondPosition]);
        setFormChanged(false);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const scorePosts = (outputTextsValue: OutputText[]) => {
    const userToken = getAuthorizedUser().authorizedUser.token;
    try {
      const fetchCarouselPromises = outputTextsValue.map(
        (output: OutputText) => {
          const scoreInputData: IScoreOutputTextInput = {
            user_token: {
              idToken: userToken,
            },
            score_text_input: {
              text: output.postText,
              contentBrief: inputData.llm_input.contentBrief,
              score_image_input: null,
            },
          };
          return dispatch(generateScore(scoreInputData));
        }
      );
      Promise.all(fetchCarouselPromises).then((values) => {
        const scores: TextScore[] = values.map((v) => v.payload);
        mergeOutputTextsWithScores(outputTextsValue, scores);
        setFormChanged(false);

        if (lessThanXL) {
          setTimeout(() => {
            carouselRef?.current?.next();
          }, 100);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const mergeOutputTextsWithScores = (
    outputTextsValue: OutputText[],
    scores: TextScore[]
  ): void => {
    const outputWithScores: OutputTextWithScore[] = (
      outputTextsValue.map((output, outputIndex) => ({
        ...output,
        score: scores.find(
          (score: TextScore, scoreIndex: number) => scoreIndex === outputIndex
        ),
      })) as OutputTextWithScore[]
    ).sort((a: OutputTextWithScore, b: OutputTextWithScore) => {
      return b.score.totalScore - a.score.totalScore;
    });
    const firstPlaceAtSecondPosition = moveItem(outputWithScores, 0, 1);
    setOutputTexts(firstPlaceAtSecondPosition);
  };

  const sortPosts = (texts: OutputTextWithScore[]) => {
    if (!texts.length) {
      return [];
    }
    const sortedOutputTexts = texts.sort(
      (a: OutputTextWithScore, b: OutputTextWithScore) => {
        return b.score.totalScore - a.score.totalScore;
      }
    );
    const firstPlaceAtSecondPosition = moveItem(sortedOutputTexts, 0, 1);

    return firstPlaceAtSecondPosition;
  };

  useEffect(() => {
    if (inputData && formChanged) {
      setOutputTexts([
        defaultOutputTextWithScore,
        defaultOutputTextWithScore,
        defaultOutputTextWithScore,
      ]);
      dispatch(generateSocialMedia(inputData)).then(({ payload }) => {
        if (!payload) {
          return;
        }
        const outputTexts: OutputText[] = payload.outputTexts;

        scorePosts(outputTexts);
      });
    }
  }, []);

  const handleToggle = (event) => {
    setIsTable(event.target.checked);
    scroller.scrollTo("title-analysis-generator", {
      duration: 5,
      smooth: false,
    });
  };

  const handleArrowClick = (direction) => {
    if (direction > 0) {
      carouselRef.current.next();
    } else {
      carouselRef.current.previous();
    }
  };

  const dots = [0, 1, 2];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box>
      {socialMedia.isError && !socialMedia.isLoading ? (
        <Box mt={1}>
          <Alert severity="error">{t("validators.somethingWrong")}</Alert>
        </Box>
      ) : (
        ""
      )}
      {socialMedia.tokensExpired ? (
        <Box mt={1}>
          <Alert severity="error">{t("validators.tokensExpired")}</Alert>
        </Box>
      ) : (
        ""
      )}
      <Grid item md={12}>
        {lessThanXL ? (
          <Box
            sx={{
              display: "grid",
            }}
          >
            <Carousel
              ref={carouselRef}
              responsive={responsive}
              autoPlay={false}
              swipeable={true}
              draggable={false}
              showDots={true}
              infinite={true}
              partialVisible={false}
              dotListClass="custom-dot-list-style"
              containerClass="carousel-container"
              itemClass="p-3"
              afterChange={(previousSlide, active) => {
                setActivePost(previousSlide, active.currentSlide, 3);
              }}
              className="fourth-step-carousel"
            >
              {sortPosts(outputTexts).map(
                (outputTextWithScore: OutputTextWithScore, index: number) => {
                  return (
                    <PostScore
                      regenerate={() => regenerate(index)}
                      key={index}
                      outputTextWithScore={outputTextWithScore}
                      index={index}
                      setEditedText={(text: string) =>
                        onSetEditedText(text, index)
                      }
                      generateImages={generateImages}
                      t={t}
                      fromEditVariants={false}
                      socialMediaTarget={
                        inputData.llm_input.contentBrief.socialMediaTarget
                      }
                      socialMedia={socialMedia}
                    />
                  );
                }
              )}
            </Carousel>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {sortPosts(outputTexts).map(
              (outputTextWithScore: OutputTextWithScore, index: number) => {
                return (
                  index < 3 && (
                    <Grid key={uuid()} item md={4}>
                      <PostScore
                        regenerate={() => regenerate(index)}
                        outputTextWithScore={outputTextWithScore}
                        index={index}
                        setEditedText={(text) => onSetEditedText(text, index)}
                        generateImages={generateImages}
                        t={t}
                        fromEditVariants={false}
                        socialMediaTarget={
                          inputData.llm_input.contentBrief.socialMediaTarget
                        }
                        socialMedia={socialMedia}
                      />
                    </Grid>
                  )
                );
              }
            )}
          </Grid>
        )}
      </Grid>

      <Grid container mt={2} spacing={2}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            textAlign: "center",
            padding: "0",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {outputTexts[0]?.postText ? (
            <>
              {/* Title on the left */}
              <Typography
                id="title-analysis-generator"
                sx={{
                  fontWeight: "bold",
                  opacity: 0.3,
                  paddingLeft: 2, // Optional padding for left alignment
                }}
              >
                {t("SMAsistant.Step4.analysisTitle")}
              </Typography>

              <Box>
                {/* Conditionally render the Toggle Switch only if not extra small screen */}
                {!isXS && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: 2,
                    }}
                  >
                    {/* Left label */}
                    <Typography
                      variant="body1"
                      sx={{
                        mr: 1,
                        color: !isTable ? "black" : "grey",
                        fontWeight: !isTable ? "bold" : "normal",
                      }}
                    >
                      {t("SMAsistant.Step4.tabPlot")}
                    </Typography>

                    <Switch
                      checked={isTable}
                      onChange={handleToggle}
                      inputProps={{ "aria-label": "Chart or Table switch" }}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: theme.palette.figma.secondary.orange.second,
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor:
                              theme.palette.figma.secondary.orange.second,
                          },
                        "& .MuiSwitch-track": {
                          backgroundColor: "grey",
                        },
                      }}
                    />

                    {/* Right label */}
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        color: isTable ? "black" : "grey",
                        fontWeight: isTable ? "bold" : "normal",
                      }}
                    >
                      {t("SMAsistant.Step4.tabTable")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          ) : null}
        </Grid>

        <Grid
          item
          md={12}
          sx={{
            display: isTable ? "flex" : undefined,
            textAlign: "center",
            padding: "0",
            justifyContent: "center",
          }}
        >
          {outputTexts[0]?.postText || outputTexts[1]?.postText ? (
            <Card
              sx={{ boxShadow: 3, borderRadius: "8px", position: "relative" }}
            >
              <CardContent>
                <Box>
                  {!isTable ? (
                    <Box>
                      <RadarChart
                        generateImages={generateImages}
                        outputTexts={sortPosts(outputTexts)}
                        t={t}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ width: "100%" }}>
                      <ScoreTable
                        outputTexts={sortPosts(outputTexts)}
                        activeCurrentSlide={activeCurrentSlide}
                        t={t}
                      />
                    </Box>
                  )}

                  {isMobile && (
                    <Box
                      sx={{
                        display: "flex",
                        position: "absolute",
                        top: "50%",
                        left: 0, // Align the left arrow to the left
                        right: 0, // Align the right arrow to the right
                        transform: "translateY(-50%)", // Vertically center the arrows
                        justifyContent: "space-between", // Space out the arrows to the left and right
                        alignItems: "center", // Vertically center the arrows
                        width: "100%",
                      }}
                    >
                      <IconButton
                        onClick={() => handleArrowClick(-2)}
                        aria-label="previous slide"
                      >
                        <ArrowBackIosNewIcon
                          sx={{
                            fontWeight: 900,
                            fontSize: "2rem",
                            color: "black",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => handleArrowClick(1)}
                        aria-label="next slide"
                        disabled={activeCurrentSlide == 3}
                      >
                        <ArrowForwardIosIcon
                          sx={{
                            fontWeight: 900,
                            fontSize: "2rem",
                            color: "black",
                          }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            width: "100%",
          }}
        >
          {isMobile && outputTexts[0]?.postText ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                width: "100%",
              }}
            >
              {dots.map((dot, index) => (
                <Box
                  key={index}
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    backgroundColor:
                      index === activeCurrentSlide ? "orange" : "#ddd",
                  }}
                />
              ))}
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};
