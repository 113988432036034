import { Grid, Typography, IconButton, Box, Container } from "@mui/material";
import * as React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import coalaLogo from "../../assets/coala_logo.svg";

export default function Footer({ t }) {
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.gradient.mainGradientZero,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={8}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              minHeight: "200px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column", // Stack elements vertically
                justifyContent: "flex-start",
                height: "50%",
                pt: 3,
                mb: 3,
              }}
            >
              <img
                src={coalaLogo}
                alt="Coala Logo"
                style={{
                  width: "40px",
                  marginLeft: "10px",
                }}
              />
              <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
                Coala.AI
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "left",
                height: "50%",
                pb: 2,
              }}
            >
              <Typography
                variant="body1"
                component="div"
                sx={{ fontWeight: 600, mb: 1, mt: 3 }}
              >
                {t("layout.footer.followUs")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: 1,
                }}
              >
                <IconButton
                  size="small"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/profile.php?id=61559152867459",
                      "_blank"
                    )
                  }
                  sx={{ p: 0, mt: 1 }}
                >
                  <FacebookIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() =>
                    window.open("https://www.instagram.com/coala_ai/", "_blank")
                  }
                  sx={{ p: 0, mt: 1 }}
                >
                  <InstagramIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, mb: 3 }}
            >
              {t("layout.footer.productTitle")}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t("layout.footer.productSM")}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t("layout.footer.productMS")}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              {t("layout.footer.productCA")}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, mb: 3 }}
            >
              {t("layout.footer.companyTitle")}
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              <ScrollLink
                to={"about-us-section"}
                smooth={true}
                duration={500}
                style={{ textDecoration: "none", color: "#262438" }}
              >
                {t("layout.footer.companyAboutUS")}
              </ScrollLink>
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              <ScrollLink
                to={"about-us-section"}
                smooth={true}
                duration={500}
                style={{ textDecoration: "none", color: "#262438" }}
              >
                {t("layout.footer.companyHistory")}
              </ScrollLink>
            </Typography>
            <Typography variant="body2">
              <ScrollLink
                to={"about-us-section"}
                smooth={true}
                duration={500}
                style={{ textDecoration: "none", color: "#262438" }}
              >
                {t("layout.footer.companyContact")}
              </ScrollLink>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, mb: 3 }}
            >
              {t("layout.footer.supportTitle")}
            </Typography>
            <Link
              to="/terms-of-use"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography variant="body2" sx={{ mb: 2 }}>
                {t("layout.footer.SupportTerms")}
              </Typography>
            </Link>
            <Link
              to="/privacy-policy"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography variant="body2" sx={{ mb: 2 }}>
                {t("layout.footer.SupportUseData")}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
