import React, { useEffect } from "react";
import "./InstagramPost.css";
import { FiRotateCw } from "react-icons/fi";
import { Box, Button } from "@mui/material";
import Markdown from "markdown-to-jsx";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";

export default function InstagramPostContent({
  edit,
  text,
  handleChange,
  setEdit,
  onSave,
  MyParagraph,
  handleCopyToClipboard,
  handleDownloadImage,
  regenerate,
  postText,
  setText,
  fromEditVariants,
  generateImages,
  t,
  open,
}) {
  const handleButtonClick = (e, action) => {
    action();
  };

  return (
    <div
      style={{
        position: "relative",
        padding: "16px",
        borderRadius: "8px",
        cursor: fromEditVariants ? "default" : "pointer",
      }}
    >
      {/* Text Content */}
      {edit ? (
        <Box mb={3} sx={{ width: "100%" }}>
          <textarea
            value={text}
            style={{ width: "100%", fontFamily: "Lexend, sans-serif" }}
            rows={18}
            onChange={handleChange}
          />
          <Box
            sx={{
              justifyContent: "end",
              display: "flex",
              paddingBottom: "10px",
            }}
          >
            <Button
              onClick={() => (setEdit(!edit), setText(postText))}
              sx={{
                marginRight: "5px",
                background: "transparent",
                color: "black",
              }}
            >
              {t("SMAsistant.InstagramPost.discard")}
            </Button>
            <Button
              onClick={() => onSave()}
              color="secondary"
              variant="contained"
            >
              {t("SMAsistant.InstagramPost.save")}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <div
            className="markdown-wrapper"
            style={{ paddingBottom: "16px", paddingTop: "8px" }}
          >
            <Markdown
              options={{
                overrides: {
                  h1: { component: MyParagraph },
                  h2: { component: MyParagraph },
                  h3: { component: MyParagraph },
                  p: { component: MyParagraph },
                  span: { component: MyParagraph },
                  strong: { component: MyParagraph },
                  b: { component: MyParagraph },
                  li: { component: MyParagraph },
                  ul: { component: MyParagraph },
                },
              }}
            >
              {postText.replace(/\n/g, "\n\n")}
            </Markdown>
          </div>
        </Box>
      )}

      {/* Buttons - visible when overlay is active and fromEditVariants is false and not edit */}
      {!fromEditVariants && open && !edit && (
        <div
          className="generator-button-container"
          style={{
            position: "absolute",
            top: generateImages ? "0%" : "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            zIndex: 10,
          }}
        >
          <Button
            color="secondary"
            variant="contained"
            onClick={(e) => handleButtonClick(e, handleCopyToClipboard)}
            sx={{
              fontWeight: "500",
              background: "#E6E6E6",
              width: "253px",
              textTransform: "none",
            }}
          >
            <ContentCopyIcon sx={{ mr: 0.3, fontWeight: "500" }} />
            {t("SMAsistant.InstagramPost.copyTooltip")}
          </Button>
          {generateImages && (
            <Button
              color="secondary"
              variant="contained"
              onClick={(e) => handleButtonClick(e, handleDownloadImage)}
              sx={{
                fontWeight: "500",
                background: "#E6E6E6",
                width: "253px",
                textTransform: "none",
              }}
            >
              <DownloadIcon sx={{ mr: 0.3, fontWeight: "500" }} />
              {t("SMAsistant.InstagramPost.downloadTooltip")}
            </Button>
          )}
          {!generateImages && (
            <Button
              color="secondary"
              variant="contained"
              onClick={(e) => handleButtonClick(e, () => setEdit(true))}
              sx={{
                fontWeight: "500",
                background: "#E6E6E6",
                width: "253px",
                textTransform: "none",
              }}
            >
              <EditIcon sx={{ mr: 0.3, fontWeight: "500" }} />
              {t("SMAsistant.InstagramPost.editTooltip")}
            </Button>
          )}
          {!generateImages && (
            <Button
              color="secondary"
              variant="contained"
              onClick={(e) => handleButtonClick(e, regenerate)}
              sx={{
                fontWeight: "500",
                background: "#FFD682",
                width: "253px",
                textTransform: "none",
              }}
            >
              <FiRotateCw
                style={{
                  marginRight: "6px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              />
              {t("SMAsistant.InstagramPost.regenerateTooltip")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
