import React from "react";
import { Box, Button, Typography, Grid, Stack, Skeleton } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { theme } from "../../theme.ts";
import { useNavigate } from "react-router-dom";
import silver from "../../assets/demo_silver.png";
import bronze from "../../assets/demo_bronze.png";
import ReactGA from "react-ga4";

const SocialMediaCard = ({
  socialMedia,
  outputTexts,
  isXS,
  t,
  closeCard,
  numOfUses,
}) => {
  const MyParagraph = ({ children, ...props }) => (
    <div
      style={{
        fontSize: "14px",
        fontWeight: "normal",
        whiteSpace: "pre-line",
      }}
      {...props}
    >
      {children}
    </div>
  );
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="center" spacing={2}>
      {!isXS && (
        <Grid item md={4}>
          <Box sx={{}}>
            <img
              src={silver}
              alt="Silver"
              style={{ height: "509px", minWidth: "329px" }}
            />
          </Box>
        </Grid>
      )}

      <Grid item md={4}>
        <Box
          sx={{
            background: theme.palette.gradient.gold,
            borderRadius: "16px",
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingLeft: "1.5px",
            paddingRight: "1.5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            minHeight: "509px",
            maxWidth: "330px",
            minWidth: "319px",
          }}
          mt={isXS ? 0 : 2}
          mb={numOfUses < 2 ? 2 : 3}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              padding: "16px",
              width: "100%",
              minHeight: "480px",
            }}
          >
            {socialMedia.isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Typography>{t("layout.DemoFeature.Loading")}</Typography>
                <Stack spacing={1} mt={1}>
                  {/* For variant="text", adjust the height via font-size */}
                  <Skeleton
                    variant="rectangular"
                    width={270}
                    height={30}
                    sx={{
                      animation: "fast-pulse 1.6s ease-in-out infinite",
                      "@keyframes fast-pulse": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0.3 },
                        "100%": { opacity: 1 },
                      },
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={270}
                    height={150}
                    sx={{
                      animation: "fast-pulse 1.6s ease-in-out infinite",
                      "@keyframes fast-pulse": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0.3 },
                        "100%": { opacity: 1 },
                      },
                    }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={270}
                    height={130}
                    sx={{
                      animation: "fast-pulse 1.6s ease-in-out infinite",
                      "@keyframes fast-pulse": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0.3 },
                        "100%": { opacity: 1 },
                      },
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={270}
                    height={60}
                    sx={{
                      animation: "fast-pulse 1.6s ease-in-out infinite",
                      "@keyframes fast-pulse": {
                        "0%": { opacity: 1 },
                        "50%": { opacity: 0.3 },
                        "100%": { opacity: 1 },
                      },
                    }}
                  />
                </Stack>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBottom: "16px",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "8px" }}>
                    <SocialIcon
                      count={210}
                      iconPath="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                    />
                    <SocialIcon
                      count={7}
                      iconPath="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                    />
                    <SocialIcon
                      count={17}
                      iconPath="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                    />
                  </Box>
                  <Box>
                    <SVGIcon path="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                  </Box>
                </Box>
                {outputTexts?.length ? (
                  <div className="markdown-wrapper">
                    <Markdown
                      options={{
                        overrides: {
                          h1: { component: MyParagraph },
                          h2: { component: MyParagraph },
                          h3: { component: MyParagraph },
                          p: { component: MyParagraph },
                          span: { component: MyParagraph },
                          strong: { component: MyParagraph },
                          b: { component: MyParagraph },
                          li: { component: MyParagraph },
                          ul: { component: MyParagraph },
                        },
                      }}
                    >
                      {outputTexts[0]?.postText.replace(/\n/g, "\n\n")}
                    </Markdown>
                  </div>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: "600",
                        marginTop: "180px",
                        color: theme.palette.figma.primary.yellow.second,
                        maxWidth: "300px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      variant="h5"
                      component="div"
                    >
                      {t("layout.DemoFeature.yourPost")}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
        {numOfUses < 2 && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Button
              sx={{
                background: "transparent",
                border: `2.5px solid ${theme.palette.figma.secondary.orange.second}`,
                color: "black",
                mb: 3,
                mt: 3,
                px: 4,
                fontWeight: 500,
                width: "100%",
                minWidth: "330px",
                maxWidth: "330px",
                fontSize: "17px",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)", // Tiny box shadow
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "transparent", // background on hover
                },
                "&:active": {
                  backgroundColor: "transparent", // background on click
                },
                "&.Mui-focusVisible": {
                  backgroundColor: "transparent", // background when focused
                },
              }}
              variant="contained"
              disabled={socialMedia.isLoading}
              onClick={closeCard}
              fullWidth={true}
            >
              {t("layout.DemoFeature.settingsBack")}
            </Button>
          </Box>
        )}
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            sx={{
              background: (theme) => theme.palette.gradient.mainGradient,
              color: "black",
              mb: isXS ? 4 : 10,
              px: 4,
              fontWeight: 500,
              width: "100%",
              minWidth: "330px",
              maxWidth: "330px",
              minHeight: "44px",
              fontSize: "20px",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)", // Tiny box shadow
            }}
            variant="contained"
            disabled={socialMedia.isLoading}
            onClick={() => {
              ReactGA.event({
                category: "user_engagement",
                action: "click",
                label: "generate_demo_cta_btn",
              });
              navigate("/dashboard/home");
            }}
            fullWidth={isXS ? true : false}
          >
            {t("layout.DemoFeature.fullVersion")}
          </Button>
        </Box>
      </Grid>

      {!isXS && (
        <Grid item md={4} sx={{ zIndex: -100 }}>
          <Box sx={{ zIndex: -100 }}>
            <img
              src={bronze}
              alt="Bronze"
              style={{ height: "509px", minWidth: "329px" }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const SocialIcon = ({ count, iconPath }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      style={{ width: "24px", height: "24px" }}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d={iconPath} />
    </svg>
    <span>{count}</span>
  </Box>
);

const SVGIcon = ({ path }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.7"
    stroke="currentColor"
    style={{ width: "24px", height: "24px" }}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d={path} />
  </svg>
);

export default SocialMediaCard;
