import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import InstagramPost from "./InstagramPost";
import { theme } from "../../../../theme.ts";

export default function PostScore({
  outputTextWithScore,
  index,
  setEditedText,
  generateImages,
  t,
  fromEditVariants,
  socialMediaTarget,
  regenerate,
  socialMedia,
}) {
  const getTotalScore = (scoreGroups: { group: string; score: number }[]) => {
    if (!scoreGroups?.length) {
      return 0;
    }
    const groupsSum = scoreGroups.reduce(
      (accumulator: number, group) => accumulator + group.score,
      0
    );

    return (groupsSum / scoreGroups.length).toFixed(1);
  };

  const sumScores = (scorePerGroup) => {
    return (
      scorePerGroup?.reduce((total, group) => total + (group.score || 0), 0) ||
      0
    );
  };

  const getColor = (index: number) => {
    switch (index) {
      case 0:
        return theme.palette.gradient.silver;
      case 1:
        return theme.palette.gradient.gold;
      case 2:
        return theme.palette.gradient.brown;
      default:
        return theme.palette.secondary.main;
    }
  };
  const color = getColor(index);

  const scoreText = generateImages
    ? t("SMAsistant.PostScore.postScore")
    : t("SMAsistant.PostScore.contentScore");
  const scoreValue = generateImages
    ? outputTextWithScore?.score?.scorePerGroup
      ? getTotalScore(outputTextWithScore?.score?.scorePerGroup)
      : "X"
    : sumScores(outputTextWithScore?.score?.scorePerGroup)
      ? outputTextWithScore?.score?.totalScore
      : "X";

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 320,
        marginLeft: "auto", // Center horizontally if the parent has some width
        marginRight: "auto", // Center horizontally if the parent has some width
        boxShadow: "none",
      }}
    >
      <InstagramPost
        postText={outputTextWithScore?.postText}
        image={outputTextWithScore?.image}
        imageExtension={outputTextWithScore?.imageExtension}
        setEditedText={setEditedText}
        generateImages={generateImages}
        fromEditVariants={fromEditVariants}
        t={t}
        socialMediaTarget={socialMediaTarget}
        regenerate={regenerate}
        socialMedia={socialMedia}
        color={color}
        scoreText={scoreText}
        scoreValue={scoreValue}
        index={index}
      />
    </Box>
  );
}
