import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Alert,
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  useMediaQuery,
  Tooltip,
  IconButton,
} from "@mui/material";
import { theme } from "../../theme.ts";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import {
  ContentBrief,
  ISocialMediaAssystentForm,
  ISocialMediaGeneratorInput,
  OutputText,
} from "../../models/social-media-assystent.ts";
import AutocompleteInfo from "../dashboard-components/social-media-assystant/components/AutocompleteInfo.tsx";
import heroIcon from "../../assets/form_hero.svg";
import realistIcon from "../../assets/form_realist.svg";
import creatorIcon from "../../assets/form_creator.svg";
import comedianIcon from "../../assets/form_comedian.svg";
import {
  generateDemoSocialMedia,
  SocialMediaState,
} from "../../http/store/reducers/social-media.ts";
import { demoToken } from "../../http/config/api.ts";
import { useDispatch, useSelector } from "react-redux";
import "../dashboard-components/social-media-assystant/components/InstagramPost.css";
import {
  getDemoUse,
  setDemoUse,
} from "../../http/local-storage/config/DemoProvider.ts";
import { scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

import DemoCard from "./DemoCard.tsx";

export default function DemoSection({ t }) {
  const { i18n } = useTranslation();
  const [showCards, setShowCards] = useState(false);
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch<any>();
  const [form, setForm] = useState<ISocialMediaAssystentForm>({
    postPurpose: [],
    talkingPoints: "",
    images: [],
    brandVoice: {
      brandArchetype: "",
      brandArchetypeDescription: "",
    },
    socialMediaTarget: "instagram",
    postLength: "long",
    targetAudience: [
      {
        gender: "all",
        ageRange: [25, 45],
        otherTraits: "",
      },
    ],
    contentType: "post",
    language: i18n.language === "en" ? "english" : "polski",
    imagesExtensions: [],
  });
  const socialMedia = useSelector(
    (state: { socialMedia: SocialMediaState }) => state.socialMedia
  );
  const [formValid, setFormValid] = useState<boolean>(true);

  const [numOfUses, setNumOfUses] = useState<number>(0);

  const [outputTexts, setOutputTexts] = useState<OutputText[]>([]);

  useEffect(() => {
    setNumOfUses(+getDemoUse());
  }, []);

  const archetypes = [
    {
      name: "hero",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc2"),
      title: t("SMAsistant.Step2.postVoiceArchetypeTitle2"),
      icon: heroIcon,
    },
    {
      name: "realist",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc3"),
      title: t("SMAsistant.Step2.postVoiceArchetypeTitle3"),
      icon: realistIcon,
    },
    {
      name: "creator",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc4"),
      title: t("SMAsistant.Step2.postVoiceArchetypeTitle4"),
      icon: creatorIcon,
    },
    {
      name: "comedian",
      description: t("SMAsistant.Step2.postVoiceArchetypeDesc5"),
      title: t("SMAsistant.Step2.postVoiceArchetypeTitle5"),
      icon: comedianIcon,
    },
  ];

  const validateStepper = (): boolean => {
    const { postPurpose, brandVoice } = form;

    if (
      !postPurpose.length ||
      !brandVoice.brandArchetype ||
      !brandVoice.brandArchetypeDescription
    ) {
      setFormValid(false);
      return false;
    } else {
      setFormValid(true);
      return true;
    }
  };

  const isArchetypeActive = (name: string) => {
    return !!(
      (form as ISocialMediaAssystentForm).brandVoice.brandArchetype === name
    );
  };

  const handlebrandArchetype = (name: string) => {
    setForm((prevState) => ({
      ...prevState,
      brandVoice: {
        brandArchetype: name,
        brandArchetypeDescription: archetypes.find((type) => type.name === name)
          ?.description,
      },
    }));
  };

  const [purposes, setPurposes] = useState<{ id: number; name: string }[]>(
    Array.from({ length: 11 }, (_, i) => ({
      id: i,
      name: t(`SMAsistant.Step1.purpose${i + 1}`),
    }))
  );

  const handleTalkingPointsChange = (e) => {
    const { value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      talkingPoints: value,
    }));
  };

  const generate = () => {
    if (!validateStepper()) {
      return;
    }
    if (numOfUses > 1) {
      return;
    }
    setDemoUse((numOfUses + 1).toString());
    setNumOfUses(numOfUses + 1);
    setShowCards(true);
    scroller.scrollTo(isXS ? "demo-title-section" : "demo-subtitle-section", {
      duration: 700,
      smooth: true,
    });

    ReactGA.event({
      category: "user_engagement",
      action: "click",
      label: "generate_demo_btn",
    });
    generateSocialMedia();
  };

  const closeCard = () => {
    setShowCards(false);
    setOutputTexts([]);
    scroller.scrollTo(isXS ? "demo-title-section" : "demo-grid-card-start", {
      duration: 500,
      smooth: true,
    });
  };

  const generateSocialMedia = () => {
    const bodyData: ContentBrief = {
      postPurpose: (form.postPurpose as string[])
        .map((item) => item["name"])
        .join(", "),
      talkingPoints: form.talkingPoints,
      brandVoice: form.brandVoice,
      socialMediaTarget: form.socialMediaTarget,
      targetAudience: form.targetAudience.map((audience) => {
        return {
          ...audience,
          ageRange: `${audience.ageRange[0]} - ${audience.ageRange[1]}`,
        };
      }),
      contentType: "post",
      language: form.language,
      postLength: form.postLength,
    };

    const inputData: ISocialMediaGeneratorInput = {
      user_token: {
        idToken: demoToken,
      },
      llm_input: {
        contentBrief: bodyData,
        images: [],
        numTxtGenerate: 1,
      },
    };

    dispatch(
      generateDemoSocialMedia(inputData as ISocialMediaGeneratorInput)
    ).then(({ payload }) => {
      if (!payload) {
        return;
      }
      const outputTexts: OutputText[] = payload.outputTexts;
      setOutputTexts(outputTexts);
    });
  };

  return (
    <Box>
      <Box
        sx={{
          background: theme.palette.figma.gradient.gradient4,
        }}
      >
        <Box
          sx={{
            paddingLeft: isXS ? "25px" : "125px",
            paddingRight: isXS ? "25px" : "125px",
            paddingBottom: "0",
            paddingTop: "0",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          maxWidth="lg"
        >
          {socialMedia.isLoading ? (
            <Box
              sx={{
                width: "100vw",
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: (theme) => theme.zIndex.drawer + 2,
              }}
            >
              <LinearProgress color="secondary" />
            </Box>
          ) : (
            ""
          )}
          {!showCards && (
            <Grid mt={7} container spacing={1}>
              <Grid item md={12} id="demo-title-section">
                <Typography
                  mt={isXS ? 7 : 9}
                  sx={{
                    textAlign: isXS ? "left" : "center",
                    fontWeight: "600",
                  }}
                  variant="h4"
                  component="div"
                >
                  {t("layout.DemoFeature.title")}
                </Typography>
              </Grid>
              <Grid item md={12} id="demo-subtitle-section">
                <Typography
                  mt={2}
                  mr={3}
                  component="div"
                  sx={{
                    textAlign: isXS ? "left" : "center",
                    maxWidth: "500px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {!showCards ? (
                    <>
                      {t("layout.DemoFeature.header1")}

                      <Tooltip
                        title={t("layout.DemoFeature.header2Tooltip")}
                        placement="bottom"
                        enterTouchDelay={0}
                        leaveTouchDelay={2500}
                      >
                        <Typography
                          sx={{ textDecoration: "underline" }}
                          display="inline"
                        >
                          {t("layout.DemoFeature.header2")}
                        </Typography>
                      </Tooltip>

                      {t("layout.DemoFeature.header3")}
                    </>
                  ) : (
                    <>{t("layout.DemoFeature.subheader")}</>
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}

          {!showCards && (
            <Grid container mt={5}>
              {!isXS ? (
                <Grid item md={2}>
                  {" "}
                </Grid>
              ) : (
                ""
              )}
              <Grid item md={isXS ? 12 : 8}>
                <Grid container>
                  <Grid item md={12}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{ color: "black", fontWeight: 350 }}
                    >
                      {t("SMAsistant.Step1.purposeTitle")}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <FormControl sx={{ width: "100%" }}>
                      <AutocompleteInfo
                        postPurpose={form.postPurpose}
                        purposes={purposes}
                        form={form}
                        setForm={setForm}
                        setPurposes={setPurposes}
                        t={t}
                        color={"#000000"}
                      />
                    </FormControl>
                    {!formValid && numOfUses < 2 && !form.postPurpose.length ? (
                      <Box mt={1}>
                        <Alert severity="error">
                          {t("validators.fieldOligatory")}
                        </Alert>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid item md={12}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{ color: "black" }}
                    >
                      {t("SMAsistant.Step1.talkingPointsTitle")}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <TextField
                      required
                      multiline
                      value={form.talkingPoints}
                      onChange={handleTalkingPointsChange}
                      rows={isXS ? 3 : 2}
                      sx={{
                        backgroundColor: "white",
                        width: "100%",
                        borderRadius: "8px",
                        border: "solid 1px  #000000",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "transparent", // Default border color
                          },
                          "&:hover fieldset": {
                            borderColor: "transparent", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent", // Border color when focused
                          },
                        },
                      }}
                      id="outlined-basic"
                      placeholder={t("SMAsistant.Step1.talkingPlaceholder")}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <Tooltip
                            title={t("SMAsistant.Step1.talkingTooltip")}
                            enterTouchDelay={0}
                            leaveTouchDelay={2500}
                          >
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                    {!formValid && numOfUses < 2 && !form.talkingPoints ? (
                      <Box mt={1}>
                        <Alert severity="error">
                          {t("validators.fieldOligatory")}
                        </Alert>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid item md={12}>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      sx={{ color: "black" }}
                    >
                      {t("SMAsistant.Step2.postVoiceTitle")}
                    </FormLabel>
                  </Grid>
                  <Grid item md={12} mt={2} mb={isXS ? 0 : 7}>
                    <FormControl fullWidth>
                      <Grid container spacing={1}>
                        {archetypes.map((type, idx) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            key={type.name}
                            mb={1.7}
                          >
                            <Card
                              onClick={() => handlebrandArchetype(type.name)}
                              variant="outlined"
                              sx={[
                                {
                                  height: "100%",
                                  minHeight: "140px",
                                  display: "flex",
                                  flexDirection: "column",
                                  cursor: "pointer",
                                  border: "0px",
                                  justifyContent: "center",
                                  position: "relative", // Needed for the pseudo-element to position correctly
                                  borderRadius: "8px", // Match the border radius of the Card
                                  overflow: "hidden", // Ensure content stays within the border radius
                                  transition: "background 0.5s",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",

                                  // Pseudo-element for the gradient border
                                  "&::before": {
                                    content: '""',
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    borderRadius: "8px",
                                    padding: "3px", // This should match your border width
                                    boxSizing: "border-box",
                                    background:
                                      "linear-gradient(0deg, rgba(255,197,77,1) 0%, rgba(237,112,105,1) 100%)",
                                    WebkitMask:
                                      "linear-gradient(#ffc54d 0 0) content-box, linear-gradient(#ffc54d 0 0)",
                                    WebkitMaskComposite: "destination-out",
                                    maskComposite: "exclude", // Compatibility for more browsers
                                    pointerEvents: "none", // Ensure it doesn't interfere with clicks
                                  },
                                },

                                // Active state for selected archetypes
                                isArchetypeActive(type.name) && {
                                  background:
                                    theme.palette.figma.gradient.gradient5,
                                },
                              ]}
                            >
                              <CardContent>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* Left side: Icon */}
                                  <img
                                    src={type.icon}
                                    alt="Icon"
                                    style={{
                                      width: "43px",
                                      height: "43px",
                                    }}
                                  />

                                  {/* Right side: Title and Description */}
                                  <Box mt={1}>
                                    <Typography
                                      fontSize={"16px"}
                                      sx={{ textTransform: "uppercase" }}
                                    >
                                      {type.title}
                                    </Typography>
                                    <FormHelperText
                                      sx={{
                                        marginLeft: "0px",
                                        fontSize: "14px",
                                        color: "rgba(0, 0, 0, 1)",
                                      }}
                                    >
                                      {type.description}
                                    </FormHelperText>
                                  </Box>
                                </Stack>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>

                      {!formValid &&
                      numOfUses < 2 &&
                      !form.brandVoice.brandArchetype ? (
                        <Box mt={1}>
                          <Alert severity="error">
                            {t("validators.fieldOligatory")}
                          </Alert>
                        </Box>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {!isXS ? (
                <Grid item md={2}>
                  {" "}
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          )}

          {!showCards && (
            <Grid container justifyContent="center" alignItems="center">
              {!isXS ? (
                <Grid item md={2}>
                  {" "}
                </Grid>
              ) : (
                ""
              )}
              <Grid
                item
                xs={12}
                md={isXS ? 12 : 8}
                mb={isXS ? 5 : 10}
                mt={isXS ? 4 : -3}
                container
                justifyContent="center"
                alignItems="center"
              >
                {numOfUses > 1 ? (
                  <Box mt={1} mb={3}>
                    <Alert severity="error">
                      {t("layout.DemoFeature.demoUsed")}
                    </Alert>
                  </Box>
                ) : null}
                <Button
                  sx={{
                    background: (theme) => theme.palette.gradient.mainGradient,
                    color: "black",
                    mb: isXS
                      ? numOfUses < 2
                        ? 12
                        : 24
                      : numOfUses < 2
                        ? 4
                        : 14,
                    px: 4,
                    fontWeight: 500,
                    width: "100%",
                    minWidth: "330px",
                    fontSize: "20px",
                    borderRadius: "8px",
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)", // Tiny box shadow
                  }}
                  variant="contained"
                  disabled={socialMedia.isLoading}
                  onClick={() => generate()}
                  fullWidth={true}
                >
                  {t("SMAsistant.generate")}
                </Button>
              </Grid>
              {!isXS ? (
                <Grid item md={2}>
                  {" "}
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          background: showCards
            ? theme.palette.figma.gradient.gradient4
            : "transparent",
        }}
      >
        <Box
          sx={{
            paddingLeft: isXS ? "25px" : "125px",
            paddingRight: isXS ? "25px" : "125px",
            paddingBottom: "0",
            paddingTop: "0",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          maxWidth="lg"
        >
          {showCards && (
            <Grid mt={7} container spacing={1}>
              <Grid item md={12} id="demo-title-section">
                <Typography
                  mt={isXS ? 7 : 9}
                  sx={{
                    textAlign: isXS ? "left" : "center",
                    fontWeight: "600",
                  }}
                  variant="h4"
                  component="div"
                >
                  {t("layout.DemoFeature.title")}
                </Typography>
              </Grid>
              <Grid item md={12} id="demo-subtitle-section">
                <Typography
                  mt={2}
                  mr={3}
                  mb={0}
                  component="div"
                  sx={{
                    textAlign: isXS ? "left" : "center",
                    maxWidth: "500px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {!showCards ? (
                    <>
                      {t("layout.DemoFeature.header1")}
                      <Typography
                        sx={{ textDecoration: "underline" }}
                        display="inline"
                      >
                        {t("layout.DemoFeature.header2")}
                      </Typography>
                      {t("layout.DemoFeature.header3")}
                    </>
                  ) : (
                    <>{t("layout.DemoFeature.subheader")}</>
                  )}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Box
            mb={showCards ? 0 : -112}
            sx={{
              opacity: showCards ? 1 : 0,
              transition: showCards
                ? "opacity 3.8s ease 0.2s, transform 3.8s ease 0.2s"
                : "opacity 2.01s ease, transform 2.01s ease",
              position: "relative",
              transform: showCards ? "translate(0, 0)" : "translate(0, 130vh)",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              id="demo-grid-card-start"
            >
              <Grid
                item
                xs={12}
                md={12}
                pl={isXS ? 0 : 5}
                pt={4}
                mb={isXS ? 5 : 2}
              >
                <DemoCard
                  socialMedia={socialMedia}
                  outputTexts={outputTexts}
                  isXS={isXS}
                  t={t}
                  closeCard={closeCard}
                  numOfUses={numOfUses}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
