import * as React from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme.ts";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setAuthorizedUser } from "../../http/local-storage/auth/AuthProvider.ts";
import Alert from "@mui/material/Alert";
import {
  AuthState,
  register,
  resetPassword,
  login,
  loginGoogleURL,
  loginGoogle,
} from "../../http/store/reducers/auth.ts";
import { redirectGoogleURL } from "../../http/config/api.ts";
import googleLogo from "../../assets/google_logo.svg";
import ReactGA from "react-ga4";

export default function Login({ t }) {
  const lessThanXL = useMediaQuery(theme.breakpoints.down("md"));
  const [isRegister, setIsRegister] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [isErrorLogin, setIsErrorLogin] = useState(false);
  const [ErrorAccountExists, setErrorAccountExists] = useState(false);
  const [isNotAutorized, setIsNotAutorized] = useState(false);
  const [isFormInValid, setIsFormInValid] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedError, setIsCheckedError] = useState(false);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const auth = useSelector((state: { auth: AuthState }) => state.auth);

  const useQueryForGoogle = () => {
    const location = useLocation();

    useEffect(() => {
      // Parse the query parameters once when the component mounts
      const queryParams = new URLSearchParams(location.search);
      const codeParam = queryParams.get("code");

      if (codeParam) {
        const decodedCode = decodeURIComponent(codeParam);
        const data = {
          redirectUri: redirectGoogleURL,
          code: decodedCode,
        };
        dispatch(loginGoogle(data)).then(({ payload }) => {
          if (String(payload).startsWith("error")) {
            setIsError(true);
            return;
          }
          setAuthorizedUser({
            ...payload,
            authorizedUser: {
              ...payload.authorizedUser,
              expirationDate: new Date(
                Date.now() + +payload.authorizedUser.tokenExpiresIn * 1000
              ).toISOString(),
            },
          });

          ReactGA.gtag("event", "login", {
            method: "Google",
          });

          navigate("../home");
        });
      }
    }, [location.search]); // This effect will run only once on mount because location.search doesn't change
  };
  useQueryForGoogle();

  const onResetPassword = () => {
    dispatch(resetPassword(email)).then(({ payload }) => {
      if (payload === "error") {
        setIsError(true);
        return;
      }
      setResetPasswordSent(true);
      setIsResetPassword(false);
      setIsError(false);
    });
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (isResetPassword) {
      onResetPassword();
      return;
    }

    if (
      (isRegister && (!username || !email || !password || !isChecked)) ||
      (!isRegister && (!email || !password))
    ) {
      if (isRegister && !isChecked) {
        setIsCheckedError(true);
      }
      setIsFormInValid(true);
      return;
    } else {
      setIsFormInValid(false);
    }

    if (isRegister) {
      const data = {
        username,
        email,
        password,
      };
      dispatch(register(data as any)).then(({ payload }) => {
        if (String(payload).includes("is already registered")) {
          setErrorAccountExists(true);
          return;
        }
        if (String(payload).startsWith("error")) {
          setIsError(true);
          return;
        }
        setIsNotAutorized(true);
        setIsError(false);
        setErrorAccountExists(false);
        setAuthorizedUser({
          ...payload,
          authorizedUser: {
            ...payload.authorizedUser,
            expirationDate: new Date(
              Date.now() + +payload.authorizedUser.tokenExpiresIn * 1000
            ).toISOString(),
          },
        });

        ReactGA.gtag("event", "sign_up", {
          method: "Email",
        });

        setIsRegister(false);
      });
    } else {
      const data = {
        email,
        password,
      };
      dispatch(login(data as any)).then(({ payload }) => {
        if (String(payload).includes("Recheck all your credentials")) {
          setIsErrorLogin(true);
          return;
        }
        if (String(payload).startsWith("error")) {
          setIsError(true);
          return;
        }
        setIsErrorLogin(false);
        setAuthorizedUser({
          ...payload,
          authorizedUser: {
            ...payload.authorizedUser,
            expirationDate: new Date(
              Date.now() + +payload.authorizedUser.tokenExpiresIn * 1000
            ).toISOString(),
          },
        });

        ReactGA.gtag("event", "login", {
          method: "Email",
        });

        if (auth?.currentUser?.authorizedUser.isVerified) {
          setIsNotAutorized(false);
          navigate("../home");
        } else {
          setIsNotAutorized(true);
        }
      });
    }
  };

  const handleGoogleURL = () => {
    const data = { redirectUri: redirectGoogleURL };
    dispatch(loginGoogleURL(data as any)).then(({ payload }) => {
      if (String(payload).startsWith("error")) {
        setIsError(true);
        return;
      }
      window.location.href = payload.authorizationUrl;
    });
  };

  return (
    <form onSubmit={submitForm}>
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "center" }}
        maxWidth="xl"
      >
        <Box sx={{ width: "400px" }}>
          <Grid item xs={12} md={12} mb={5} mt={lessThanXL ? 10 : 0}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h4" component="div">
                {isRegister
                  ? t("resposiveDrawer.Login.title3")
                  : t("resposiveDrawer.Login.title1")}
              </Typography>
              <Typography
                sx={{
                  marginLeft: "7px",
                  background: (theme) => theme.palette.figma.gradient.gradient2,
                  fontWeight: "bold",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                variant="h4"
                component="div"
              >
                {t("resposiveDrawer.Login.title2")}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12}>
            {isResetPassword ? (
              <>
                <Box>
                  <TextField
                    autoFocus
                    variant="outlined"
                    margin="dense"
                    id="name"
                    name="email"
                    placeholder="email"
                    type="email"
                    error={!email}
                    helperText={!email ? t("validators.fieldOligatory") : ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(event.target.value);
                    }}
                    fullWidth
                    sx={{
                      borderRadius: "8px",
                      border: "solid 1px  #575757",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent", // Border color when focused
                        },
                      },
                    }}
                  />
                </Box>
                <Box mt={3} sx={{ textAlign: "left" }}>
                  <Button onClick={() => setIsResetPassword(!isResetPassword)}>
                    {t("resposiveDrawer.Login.comeLogin")}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <TextField
                    autoFocus
                    variant="outlined"
                    margin="dense"
                    id="email-name-login"
                    name="email"
                    placeholder="email"
                    type="email"
                    error={isFormInValid && !email}
                    helperText={
                      isFormInValid && !email
                        ? t("validators.fieldOligatory")
                        : ""
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setIsFormInValid(false);
                      setEmail(event.target.value);
                    }}
                    fullWidth
                    sx={{
                      borderRadius: "8px",
                      border: "solid 1px  #575757",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent", // Border color when focused
                        },
                      },
                    }}
                  />
                </Box>

                <Box mt={2}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    id="password"
                    name="password"
                    placeholder={t("resposiveDrawer.Login.password")}
                    error={isFormInValid && !password}
                    helperText={
                      isFormInValid && !password
                        ? t("validators.fieldOligatory")
                        : ""
                    }
                    type="password"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPassword(event.target.value);
                      setIsFormInValid(false);
                    }}
                    fullWidth
                    sx={{
                      borderRadius: "8px",
                      border: "solid 1px  #575757",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent", // Border color when focused
                        },
                      },
                    }}
                  />
                </Box>
                <Box mt={1} sx={{ textAlign: "left" }}>
                  <Typography component="span">
                    {t("resposiveDrawer.Login.forgotPassword")}
                    <Button
                      onClick={() => setIsResetPassword(!isResetPassword)}
                      sx={{
                        textDecoration: "underline",
                        color: "#FF6663",
                        padding: 0,
                        textTransform: "none",
                        marginLeft: "8px",
                      }}
                    >
                      {" "}
                      {t("resposiveDrawer.Login.forgotPassword2")}{" "}
                    </Button>
                  </Typography>
                </Box>

                {isRegister ? (
                  <Box>
                    <Box mt={2}>
                      <TextField
                        variant="outlined"
                        margin="dense"
                        id="username"
                        name="username"
                        placeholder={t("resposiveDrawer.Login.username")}
                        type="username"
                        error={isFormInValid && !username}
                        helperText={
                          isFormInValid && !username
                            ? t("validators.fieldOligatory")
                            : ""
                        }
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setUsername(event.target.value);
                          setIsFormInValid(false);
                        }}
                        fullWidth
                        sx={{
                          borderRadius: "8px",
                          border: "solid 1px  #575757",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "transparent", // Default border color
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent", // Border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent", // Border color when focused
                            },
                          },
                        }}
                      />
                    </Box>
                    <Box mt={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                          />
                        }
                        label={
                          <span>
                            {t("resposiveDrawer.Login.Checkbox1")}
                            <a href="/privacy-policy">
                              {t("resposiveDrawer.Login.Checkbox2")}
                            </a>{" "}
                            {t("resposiveDrawer.Login.Checkbox3")}{" "}
                            <a href="/terms-of-use">
                              {t("resposiveDrawer.Login.Checkbox4")}
                            </a>
                          </span>
                        }
                      />
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
              </>
            )}
            <Box mt={3}>
              {resetPasswordSent ? (
                <Alert
                  severity="success"
                  onClose={() => {
                    setResetPasswordSent(false);
                  }}
                >
                  {t("resposiveDrawer.Login.activationLink")}
                </Alert>
              ) : (
                ""
              )}
            </Box>

            <Box mt={3}>
              {isCheckedError && (
                <Alert
                  severity="error"
                  onClose={() => setIsCheckedError(false)}
                >
                  {t("validators.AcceptFieldTermsPrivacy")}
                </Alert>
              )}
            </Box>

            <Box mt={3}>
              {isErrorLogin ? (
                <Alert
                  severity="error"
                  onClose={() => {
                    setIsErrorLogin(false);
                  }}
                >
                  {t("validators.wrongCredentials")}
                </Alert>
              ) : (
                ""
              )}
            </Box>

            <Box mt={3}>
              {ErrorAccountExists ? (
                <Alert
                  severity="error"
                  onClose={() => {
                    setErrorAccountExists(false);
                  }}
                >
                  {t("validators.accountExists")}
                </Alert>
              ) : (
                ""
              )}
            </Box>
            <Box mt={3}>
              {isError ? (
                <Alert
                  severity="error"
                  onClose={() => {
                    setIsError(false);
                  }}
                >
                  {t("validators.somethingWrong")}
                </Alert>
              ) : (
                ""
              )}
            </Box>
            <Box mt={3}>
              {isNotAutorized ? (
                <Alert
                  severity="success"
                  onClose={() => {
                    setIsNotAutorized(false);
                  }}
                >
                  {t("resposiveDrawer.Login.confirmEmail")}
                </Alert>
              ) : (
                ""
              )}
            </Box>
            <Box mt={5} sx={{}}>
              <Button
                disabled={auth?.isLoading}
                sx={{
                  background: (theme) => theme.palette.gradient.mainGradient,
                  minHeight: "44px",
                  width: "100%",
                  borderRadius: "8px",
                  fontSize: "20px",
                }}
                variant="contained"
                color="secondary"
                type="submit"
              >
                {isResetPassword
                  ? t("resposiveDrawer.Login.send")
                  : isRegister
                    ? t("resposiveDrawer.Login.register")
                    : t("resposiveDrawer.Login.log")}
              </Button>
            </Box>
            <Box mt={3} sx={{ textAlign: "center" }}>
              {!isResetPassword ? (
                <>
                  {isRegister
                    ? t("resposiveDrawer.Login.haveAccountQ")
                    : t("resposiveDrawer.Login.noAccount")}
                  <Box
                    component="span"
                    onClick={() => setIsRegister(!isRegister)}
                    sx={{
                      cursor: "pointer",
                      position: "relative",
                      display: "inline-block",
                      mt: 1,
                      ml: 1,
                      background: "#FF6663",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      "&::after": {
                        content: '""',
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        height: "2px", // Thickness of the underline
                        background: "#FF6663",
                        transform: "scaleX(1)",
                        transformOrigin: "left",
                        transition: "transform 0.3s ease-in-out",
                      },
                      "&:hover::after": {
                        transform: "scaleX(1.1)", // Adds a slight hover effect for animation
                      },
                    }}
                  >
                    {isRegister
                      ? t("resposiveDrawer.Login.haveAccount")
                      : t("resposiveDrawer.Login.register")}
                  </Box>
                </>
              ) : (
                ""
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              my={2}
              mt={3}
            >
              <Box flex={1} height="1.5px" bgcolor="grey.800" mx={1}></Box>
              <Typography
                sx={{ fontWeight: 400, fontSize: 18 }}
                color="black"
                mx={2}
              >
                {t("resposiveDrawer.Login.or")}
              </Typography>
              <Box flex={1} height="1.5px" bgcolor="grey.800" mx={1}></Box>
            </Box>
            <Box mt={3} sx={{ textAlign: "left" }}>
              <Button
                variant="outlined"
                startIcon={
                  <img
                    src={googleLogo}
                    alt="Google logo"
                    style={{ width: 44, height: 44 }} // Adjust size of the logo
                  />
                }
                sx={{
                  width: "100%",
                  backgroundColor: "#ffffff",
                  color: "#575757",
                  textAlign: "left", // Align text to the left
                  textTransform: "none", // Disable uppercase
                  borderColor: "#dadce0", // Light border to match Google's button
                  boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)", // Subtle shadow effect
                  padding: "8px 16px", // Proper padding for the button
                  fontSize: "16px",
                  fontWeight: 600,
                  display: "flex", // Use flexbox to align the icon and text
                  alignItems: "center", // Center the items vertically
                  justifyContent: "flex-start", // Align content to the left
                  "&:hover": {
                    backgroundColor: "#f8f9fa", // Google hover effect
                    borderColor: "#dadce0",
                    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)", // Increase shadow on hover
                  },
                  borderRadius: "8px",
                  border: "solid 1px  #575757",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent", // Border color when focused
                    },
                  },
                }}
                onClick={() => {
                  handleGoogleURL();
                }}
              >
                {!isRegister
                  ? t("resposiveDrawer.Login.googleLogin")
                  : t("resposiveDrawer.Login.googleRegister")}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </form>
  );
}
